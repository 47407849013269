.about-us-banner-section {
  display: flex;
  background-color: #FFFAFE;
  margin-top: 80px;
  max-height: 500px;
  align-items: center;
  gap: 30px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .about-us-banner-section {
    flex-direction: column-reverse;
    max-height: none;
    align-items: flex-start;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .about-us-banner-section {
    max-height: none;
  }
}
.about-us-banner-section .left {
  flex: 1;
}
.about-us-banner-section .left h1 {
  font-weight: 900;
}
.about-us-banner-section .left h1 .black {
  font-size: 64px;
  color: #262626;
}
.about-us-banner-section .left h1 .green-color {
  font-size: 96px;
  color: #4B9E99;
}
.about-us-banner-section .left h1 .yellow-color {
  font-size: 96px;
  color: #F9D54A;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .about-us-banner-section .left h1 {
    line-height: 64px;
  }
  .about-us-banner-section .left h1 .black {
    font-size: 40px;
    color: #262626;
  }
  .about-us-banner-section .left h1 .green-color {
    font-size: 64px;
    color: #4B9E99;
  }
  .about-us-banner-section .left h1 .yellow-color {
    font-size: 64px;
    color: #F9D54A;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .about-us-banner-section .left h1 .black {
    font-size: 40px;
    color: #262626;
  }
  .about-us-banner-section .left h1 .green-color {
    font-size: 64px;
    color: #4B9E99;
  }
  .about-us-banner-section .left h1 .yellow-color {
    font-size: 64px;
    color: #F9D54A;
  }
}
.about-us-banner-section .right {
  flex: 1;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .about-us-banner-section .right {
    display: none;
  }
}
.about-us-banner-section .right .img-container {
  height: 600px;
  width: 100%;
}
.about-us-banner-section .right .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.about-us {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .about-us {
    flex-direction: column;
  }
}
.about-us .left {
  flex: 1;
}
.about-us .left .img-container {
  height: 500px;
  width: 100%;
}
.about-us .left .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.about-us .right {
  flex: 1;
}
.about-us .right .title {
  font-size: 1.5rem;
}
.about-us .right p {
  text-align: justify;
}

.why-us {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
}
.why-us .box-container .title {
  font-size: 1.5rem;
}
.why-us .box-container .box {
  padding: 40px;
  border: 1px solid black;
  border-radius: 10px;
  min-height: none !important;
  width: 100%;
  height: unset;
}
.why-us .box-container .box p {
  text-align: justify;
}

.story-section .story-container {
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 40px;
  background-color: #EFF4FF;
  border-radius: 10px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .story-section .story-container {
    flex-direction: column;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .story-section .story-container {
    flex-direction: column;
  }
}
.story-section .story-container .left {
  flex: 1;
}
.story-section .story-container .left .img-container {
  width: 100%;
}
.story-section .story-container .left .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.story-section .story-container .right {
  flex: 1;
}
.story-section .story-container .right p {
  text-align: justify;
}

.our-story .team {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 100px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .our-story .team {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .our-story .team {
    display: flex;
    flex-direction: column;
  }
}
.our-story .team:nth-child(odd) {
  flex-direction: row-reverse;
  gap: 50px;
  margin-bottom: 100px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .our-story .team:nth-child(odd) {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .our-story .team:nth-child(odd) {
    display: flex;
    flex-direction: column;
  }
}
.our-story .team .left {
  flex: 1;
}
.our-story .team .left .year {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4B9E99;
  align-self: flex-start;
}
.our-story .team .left .img-container {
  height: 300px;
  width: 500px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .our-story .team .left .img-container {
    width: 100%;
  }
}
.our-story .team .left .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.our-story .team .right {
  flex: 1;
}
.our-story .team .right .name {
  margin-bottom: 30px;
  font-size: 2rem;
  color: #262626;
  font-weight: bold;
}/*# sourceMappingURL=about.css.map */