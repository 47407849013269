.toy-detail-container {
  display: flex;
  gap: 40px;
  padding-top: 50px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .toy-detail-container {
    flex-direction: column;
    margin-top: 100px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .toy-detail-container {
    flex-direction: column;
    margin-top: 150px;
  }
}
.toy-detail-container .left {
  flex: 1;
}
.toy-detail-container .left .main-img {
  height: 500px;
  width: 500px;
  position: relative;
  background-color: #FFFAFE;
  padding: 40px;
  border-radius: 5px;
  border: 1px solid #D3D3D3;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .toy-detail-container .left .main-img {
    width: 100%;
    height: 300px;
  }
}
.toy-detail-container .left .main-img .toy-img {
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  position: absolute;
}
.toy-detail-container .left .main-img .toy-img .img-container {
  height: 100%;
  width: 100%;
}
.toy-detail-container .left .main-img .toy-img .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.toy-detail-container .left .main-img .toy-img .icon-container {
  position: absolute;
  top: 20px;
  right: 20px;
}
.toy-detail-container .left .main-img .toy-img .filled svg path {
  fill: #F54C4C;
}
.toy-detail-container .left .img-nav-container {
  display: flex;
  margin-top: 40px;
}
.toy-detail-container .left .img-nav-container .img-nav {
  padding: 10px;
  margin-right: 20px;
  background-color: #FFFAFE;
  border-radius: 5px;
  border: 1px solid #D3D3D3;
}
.toy-detail-container .left .img-nav-container .img-nav .img-container {
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.toy-detail-container .left .img-nav-container .img-nav .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.toy-detail-container .left .img-nav-container .selected {
  border: 1px solid #4B9E99;
}
.toy-detail-container .right {
  flex: 2;
}
.toy-detail-container .right .product-title h1 {
  font-size: 2rem;
  font-weight: 800;
  text-transform: none;
  margin: 0;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .toy-detail-container .right .product-title h1 {
    line-height: 50px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .toy-detail-container .right .product-title h1 {
    line-height: 50px;
  }
}
.toy-detail-container .right .product-detail-container .product-detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.toy-detail-container .right .product-detail-container .product-detail .detail {
  color: #4B9E99;
  font-size: 0.8rem;
}

.product-description {
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  color: rgb(212, 154, 52);
}/*# sourceMappingURL=toydetail.css.map */