.counter-box {
  position: relative;
  display: grid;
  place-items: center;
  min-height: 200px;
}
.counter-box .card-title {
  color: #4B9E99;
  position: absolute;
  top: 20px;
  left: 20px;
}
.counter-box span {
  font-size: 4rem;
  font-weight: 700;
  color: #262626;
}
.counter-box .date-container {
  display: flex;
  gap: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.counter-box .date-container .date {
  display: flex;
  padding: 10px;
  background: rgba(183, 33, 38, 0.13);
  border: 1px solid #B72126;
  font-size: 12px;
  color: #B72126;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
}
.counter-box .date-container .date span {
  font-size: 12px;
  font-weight: 400;
  color: #B72126;
}
.counter-box .img-container {
  height: 100px;
  width: 100px;
}
.counter-box .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.popular-box .card-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .popular-box .card-title-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .popular-box .card-title-container {
    align-items: flex-start;
    gap: 40px;
    flex-direction: column;
  }
}
.popular-box .card-title-container .card-title {
  color: #4B9E99;
}
.popular-box .card-title-container .button-flex {
  display: flex;
  gap: 10px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .popular-box .card-title-container .button-flex {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .popular-box .card-title-container .button-flex {
    flex-wrap: wrap;
  }
}
.popular-box .popular-product {
  display: flex;
  gap: 40px;
  margin-top: 20px;
  align-items: center;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .popular-box .popular-product {
    flex-direction: column;
    align-items: flex-start;
  }
}
.popular-box .popular-product .left {
  flex: 1;
  text-align: center;
  font-weight: 600;
}
.popular-box .popular-product .left .img-container {
  height: 300px;
  width: 100%;
  margin-bottom: 10px;
}
.popular-box .popular-product .left .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.popular-box .popular-product .right {
  flex: 1;
  justify-self: flex-end;
}
.popular-box .popular-product .right .heading {
  font-size: 1rem;
}
.popular-box .popular-product .right .block {
  margin: 10px 0;
  font-size: 0.8rem;
  color: #7E7E7E;
}
.popular-box .popular-product .right .block span {
  font-size: 2rem;
  color: #7E4B9E;
  font-weight: 600;
}

.chart {
  display: grid;
  place-items: center;
  height: 100%;
}

form .name {
  font-weight: 600;
  color: #4B9E99;
  margin-bottom: 20px;
  font-size: 1rem;
  margin-top: 40px;
}
form .name:first-child {
  margin-top: 0;
}
form .form-group-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
form .form-group {
  display: flex;
  gap: 20px;
}
@media screen and (max-width: 700px) {
  form .form-group {
    flex-direction: column;
  }
}
form .upload-btn {
  padding: 10px;
  border: 1px dotted #e2e2e2;
  border-radius: 10px;
  height: 300px;
  width: 100%;
  position: relative;
  color: #9e9e9e;
  cursor: pointer;
  background-color: #e2e2e2;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
form .upload-btn img {
  width: 150px;
  -o-object-fit: contain;
     object-fit: contain;
}
form input[type=submit] {
  cursor: pointer;
  border: 0;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #4B9E99 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B9E99 !important;
}

.d-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}/*# sourceMappingURL=DashboardComponents.css.map */