header {
  background-color: white;
  width: 100%;
  top: 0;
  z-index: 4;
  position: sticky;
  top: 0;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  header {
    margin-bottom: 0;
  }
}
header .desktop-nav {
  display: flex;
  align-items: center;
  padding: 15px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  position: relative;
}
header .desktop-nav .MuiInputBase-root {
  background-color: transparent;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  header .desktop-nav {
    display: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  header .desktop-nav {
    display: none;
  }
}
header .desktop-nav .marquee-text {
  width: 60%;
  position: absolute;
  top: 5px;
  right: 0;
  color: #4B9E99;
  font-weight: 600;
}
header .desktop-nav .left {
  display: flex;
  align-items: center;
}
header .desktop-nav .center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  flex: 1;
}
header .desktop-nav .center form {
  width: 100%;
}
header .desktop-nav .right {
  flex: 5;
  display: flex;
  justify-content: flex-end;
}
header .desktop-nav .right nav {
  display: flex;
  align-items: center;
}
header .desktop-nav .right nav ul {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;
}
header .desktop-nav .right nav ul li {
  padding-right: 30px;
  color: #4B9E99;
  transition: all 0.5s ease;
  font-weight: 600;
}
header .desktop-nav .right nav ul li a {
  color: #4B9E99;
  transition: all 0.5s ease;
  font-weight: 600;
}
header .desktop-nav .right nav ul li a:hover {
  color: #2f6d69;
}
header .desktop-nav .right nav ul li .icon-container {
  height: 30px;
  width: 30px;
  position: relative;
}
header .desktop-nav .right nav ul li .icon-container svg {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
header .desktop-nav .right nav ul li .icon-container .text-container {
  height: 25px;
  width: 25px;
  background-color: #F9D54A;
  color: black;
  font-size: 0.8rem;
  display: grid;
  place-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 20px;
}
header .desktop-nav .right nav ul .profile-btn {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #4B9E99;
  color: white;
  background-color: #4B9E99;
}
header .desktop-nav .right nav ul .login {
  margin-right: 40px;
}
header .mobile-nav-bar {
  padding: 10px 10px;
  border-bottom: 1px solid #E8E8E8;
  display: none;
}
header .mobile-nav-bar .MuiInputBase-root {
  background-color: transparent;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  header .mobile-nav-bar {
    display: block;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  header .mobile-nav-bar {
    display: block;
  }
}
header .mobile-nav-bar .mobile-nav {
  align-items: center;
  display: none;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  header .mobile-nav-bar .mobile-nav {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  header .mobile-nav-bar .mobile-nav {
    display: flex;
    flex-direction: row;
  }
}
header .mobile-nav-bar .mobile-nav .left {
  flex: 1;
}
header .mobile-nav-bar .mobile-nav .left .logo-container {
  width: 150px;
  float: left;
}
header .mobile-nav-bar .mobile-nav .left .logo-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
header .mobile-nav-bar .mobile-nav .right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .mobile-nav-bar .mobile-nav .right .mobile-list {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background-color: white;
  border-left: 1px solid #E8E8E8;
  z-index: 300;
  transition: all 0.5s ease;
  padding: 40px;
  padding-top: 100px;
}
header .mobile-nav-bar .mobile-nav .right .mobile-list nav {
  margin-top: 20px;
}
header .mobile-nav-bar .mobile-nav .right .mobile-list nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
header .mobile-nav-bar .mobile-nav .right .mobile-list nav ul li {
  color: white;
  margin-bottom: 20px;
}
header .mobile-nav-bar .mobile-nav .right .active {
  width: 80%;
  transition: all 0.5s ease;
  right: 0;
}
header .mobile-nav-bar .mobile-nav .right .inactive {
  width: 0%;
  transition: all 0.5s ease;
  right: -60%;
}
header .mobile-nav-bar .mobile-nav .right .hamburger-react {
  z-index: 301;
}/*# sourceMappingURL=header.css.map */