$primary:    #4B9E99;
$secondary:  #F6D64B;

.btn-container {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    // position: absolute;
    // bottom: 20px;
    margin-top: 40px;
    bottom: 20px;
    cursor: pointer;
    .rent-now {
        display: flex;
        align-items: center;
        background-color: $primary;
        width: fit-content;
        padding: 12px 10px;
        border-radius: 5px;
        position: relative;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            padding: 8px;
        }
      
        .icon-container {
            height: 20px;
            width: 20px;
            margin-right: 10px;
            @media screen and (min-width: 300px) and (max-width: 480px) {
                height: 15px;
                width: 15px;
            }
            svg {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        span {
            color: white;
            font-weight: 700;
            font-size: 0.8rem;
        }
    }

    .add-to-cart {
        padding: 10px 20px;
        border-radius: 5px;
        background-color: $secondary;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            padding: 8px;
        }
        span {
            color: #262626;
            font-weight: 700;
            font-size: 0.8rem;

        }
    }
    .red{
        background: rgba(183, 33, 38, 0.13);
        border-radius: 5px;
        border: 1px solid #B72126;
        span{
            font-weight: normal!important;
            color: #B72126!important;
        }
    }
    .whatsapp{
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 10px;
        background-color: #25D366;
        color: white;
        .icon-container{
            height: 20px;
            width: 20px;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
    .out-of-stock{
        background: rgba(183, 33, 38, 0.13);
        border-radius: 5px;
        border: 1px solid #B72126;
        padding: 12px 10px;
        border-radius: 5px;
        cursor: default;
        span{
            font-weight: normal!important;
            color: #B72126!important;
        }
    }
}

.button{
    padding:10px 20px;
    color: white;
    border-radius: 5px;
    width: fit-content;
    font-weight: 600;
    cursor: pointer;
}
.green{
    background-color: $primary;

}
.yellow{
    background-color: $secondary;
}
