$black:#262626;
$green:#4B9E99;
$yellow:#F9D54A;
$pink:#FFFAFE;

.subscription-container {
    padding-top: 50px;

    .grid-container {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;

        @media screen and (min-width: 300px) and (max-width: 480px) {
            grid-template-columns: 1fr;
        }

        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.exhausted-credit-container {
    background-color: $yellow;
    padding: 20px 40px !important;
    display: flex;
    gap: 50px;
    align-items: center;
    border-radius: 10px;

    .left {
        .img-container {
            height: 300px;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .right {
        flex: 1;

        .content {
            flex: 2;

            h3 {
                font-size: 2.5rem;
                font-weight: 400;
            }
        }

        form {
            flex: 1;

            .form-group-container {
                .formgroup {
                    display: flex;
                    gap: 10px;
                    margin-bottom: 10px;

                    .button {
                        padding: 5px 5px;
                        border: 1px solid $black;
                        border-radius: 5px;
                        color: $black;
                        font-size: 0.875rem;
                        font-weight: 600;
                        cursor: pointer;
                    }

                    input[type=submit] {
                        background-color: $green;
                        color: white;
                        padding: 10px 20px;
                        border-radius: 4px;
                        border: none;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.cart-wallet {
    gap: 40px;
    display: flex;
}