$pink:#FFFAFE;
$green:#4B9E99;
$yellow:#F9D54A;
$darkgrey:#7E7E7E;
$black:#262626;

.counter-box {
    position: relative;
    display: grid;
    place-items: center;
    min-height: 200px;

    .card-title {
        color: $green;
        position: absolute;
        top: 20px;
        left: 20px;
    }

    span {
        font-size: 4rem;
        font-weight: 700;
        color: $black;
    }
    .date-container{
        display: flex;
        gap: 20px;
        position: absolute;
        bottom: 20px;
        right: 20px;
        .date{
            display: flex;
            padding: 10px ;
            background: rgba(183, 33, 38, 0.13);
            border: 1px solid #B72126;
            font-size: 12px;
            color: #B72126;
            gap: 10px;
            align-items: center;
            border-radius: 5px;
            span{
                font-size: 12px;
                font-weight: 400;
                color: #B72126;
            }
        }
    }
    .img-container{
        height: 100px;
        width: 100px;
        img{
            height: 100%;
            width: 100%;
            object-fit:contain;
        }
    }

}

.popular-box {
    .card-title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;
        }
    
        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            align-items: flex-start;
            gap: 40px;
            flex-direction: column;
        }
        .card-title {
            color: $green;
        }

        .button-flex {
            display: flex;
            gap: 10px;
            @media screen and (min-width: 300px) and (max-width: 480px) {
                flex-wrap: wrap;
            }
        
            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                flex-wrap: wrap;
            }
        }

    }

    .popular-product {
        display: flex;
        gap: 40px;
        margin-top: 20px;
        align-items: center;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .left {
            flex: 1;
            text-align: center;
            font-weight: 600;

            .img-container {
                height: 300px;
                width: 100%;
                margin-bottom: 10px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .right {
            flex: 1;
            justify-self: flex-end;

            .heading {
                font-size: 1rem;
            }

            .block {
                margin: 10px 0;
                font-size: 0.8rem;
                color: $darkgrey;

                span {
                    font-size: 2rem;
                    color: #7E4B9E;
                    font-weight: 600;
                }
            }
        }
    }
}

.chart {
    display: grid;
    place-items: center;
    height: 100%;
}


form {

  
    .name {
        font-weight: 600;
        color: $green;
        margin-bottom: 20px;
        font-size: 1rem;
        margin-top: 40px;

        &:first-child {
            margin-top: 0;
        }
    }

    .form-group-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

    }

    .form-group {
        display: flex;
        gap: 20px;
        @media screen and (max-width: 700px) {
            flex-direction: column;
        }
    }

    .upload-btn {
        padding: 10px;
        border: 1px dotted #e2e2e2;
        border-radius: 10px;
        height: 300px;
        width: 100%;
        position: relative;
        color: #9e9e9e;
        cursor: pointer;
        background-color: #e2e2e2;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

            img {
                // height: 150px;
                width: 150px;
                object-fit: contain;
            }
    }

    input[type=submit] {
        cursor: pointer;
        border: 0;
    }
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    
    color: $green!important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: $green!important;
}

.d-flex{
    display: flex;
    align-items: center;
    gap: 10px;
}