.product-card {
  border-radius: 10px;
  width: 100%;
  border: 1px solid #D3D3D3;
  position: relative;
  max-width: 300px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .product-card {
    min-height: unset;
    max-width: unset;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .product-card {
    max-width: 400px;
  }
}
.product-card .content {
  padding: 20px;
}
.product-card .content .product-img {
  position: relative;
  background-color: white;
}
.product-card .content .product-img .img-container {
  height: 200px;
  width: 100%;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .product-card .content .product-img .img-container {
    height: 230px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .product-card .content .product-img .img-container {
    height: 230px;
  }
}
.product-card .content .product-img .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.product-card .content .product-img .filled svg path {
  fill: #F54C4C;
}
.product-card .content .product-img .icon-container {
  position: absolute;
  height: 25px;
  width: 25px;
  top: 20px;
  right: 10px;
}
.product-card .content .product-img .icon-container svg {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.product-card .content .product-description {
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  color: rgb(212, 154, 52);
}
.product-card .content .product-name {
  font-weight: 700;
  margin-top: 20px;
  font-size: 1.2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Limit to 3 lines */
  line-clamp: 1;
  text-overflow: ellipsis;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .product-card .content .product-name {
    font-size: 0.8rem;
  }
}
.product-card .content .stock {
  color: #34C83A;
  margin-top: 20px;
  font-size: 0.8rem;
  display: none;
}/*# sourceMappingURL=card.css.map */