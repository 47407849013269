.flex-direction {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start;
}

.login-container {
  display: flex;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .login-container {
    flex-direction: column;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .login-container {
    flex-direction: column;
  }
}
.login-container .left {
  flex: 1;
  background-color: #709E4B;
  min-height: 100vh;
  padding: 50px 0;
  position: relative;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .login-container .left {
    display: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .login-container .left {
    display: none;
  }
}
.login-container .left .logo-container {
  width: 400px;
  margin: auto;
}
.login-container .left .logo-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.login-container .left .img-container {
  height: auto;
  width: 105%;
  position: absolute;
  bottom: -5%;
}
.login-container .left .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.login-container .right {
  flex: 1;
  margin: auto;
}
.login-container .right .logo-container {
  width: 100%;
  display: none;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .login-container .right .logo-container {
    display: block;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .login-container .right .logo-container {
    display: block;
  }
}
.login-container .right .logo-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .login-container .right {
    min-height: 100vh;
    display: grid;
    place-items: center;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .login-container .right {
    min-height: 100vh;
    display: grid;
    place-items: center;
  }
}
.login-container .right .form {
  margin: auto;
  width: 300px;
  padding: 100px 0;
}
.login-container .right .form .username {
  text-transform: lowercase !important;
}
.login-container .right .form .error {
  padding: 20px;
  background-color: rgba(183, 33, 38, 0.13);
  color: #B72126;
  border: 1px solid #B72126;
  border-radius: 5px;
  margin: 40px 0;
}
.login-container .right .form .form-group-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.login-container .right .form .form-group-container .form-group {
  display: flex;
  gap: 20px;
}

.registeration-container {
  display: flex;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .registeration-container {
    flex-direction: column;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .registeration-container {
    flex-direction: column;
  }
}
.registeration-container .left {
  flex: 1;
  background-color: #709E4B;
  min-height: 100vh;
  padding: 50px 0;
  position: relative;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .registeration-container .left {
    display: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .registeration-container .left {
    display: none;
  }
}
.registeration-container .left .logo-container {
  width: 400px;
  margin: auto;
}
.registeration-container .left .logo-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.registeration-container .left .img-container {
  height: auto;
  width: 105%;
  position: absolute;
  bottom: -5%;
}
.registeration-container .left .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.registeration-container .right {
  flex: 1;
  margin: auto;
}
.registeration-container .right .logo-container {
  width: 100%;
  display: none;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .registeration-container .right .logo-container {
    display: block;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .registeration-container .right .logo-container {
    display: block;
  }
}
.registeration-container .right .logo-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .registeration-container .right {
    min-height: 100vh;
    display: grid;
    place-items: center;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .registeration-container .right {
    min-height: 100vh;
    display: grid;
    place-items: center;
  }
}
.registeration-container .right .form {
  margin: auto;
  width: 100%;
  padding: 100px;
}
.registeration-container .right .form .error {
  padding: 20px;
  background-color: rgba(183, 33, 38, 0.13);
  color: #B72126;
  border: 1px solid #B72126;
  border-radius: 5px;
  margin: 40px 0;
}
.registeration-container .right .form .form-group-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.registeration-container .right .form .form-group-container .form-group {
  display: flex;
  gap: 20px;
}/*# sourceMappingURL=login.css.map */