$pink:#FFFAFE;
$green:#4B9E99;
$yellow:#F9D54A;

.store-banner-container {
    padding-top: 20px;
    position: relative;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        margin-top: 80px!important;
    }
    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        margin-top: 80px!important;
    }
    .store-slide {
        background-color: $pink;
        padding: 20px 40px;
        display: flex !important;
        align-items: center;
        border-radius: 10px;
        gap: 40px;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            flex-direction: column-reverse;
            padding: 20px;
        }
    
        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            flex-direction: column-reverse;
        }
        .left {
            flex: 1;

            .title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                h3 {
                    font-size: 30px;
                    margin-bottom: 10px;
                    font-weight: 800;
                    line-height: 30px;
                    text-transform: uppercase;
                    @media screen and (min-width: 300px) and (max-width: 480px) {
                        font-size: 30px;
                        line-height: 30px;
                        margin-bottom: 20px;
                    }
                }

                span {
                    font-size: 1.2rem;
                }
            }
            .btn-container{
                position: static;
            }
        }

        .right {
            flex: 1;
            .img-container{
                height: 500px;
                width: 100%;
                @media screen and (min-width: 300px) and (max-width: 480px) {
                    height: 300px;
                }
            
                // iPads, Tablets
                @media screen and (min-width: 481px) and (max-width: 1200px) {
                    height: 400px;
                }
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .prev{
        left: 0%;
    }
    .next{
        right: 0%;
    }
}

.store-container {
    display: flex;
    gap: 20px;
    position: relative;
    width: 100%;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        flex-direction: column;
    }

    .mobile-filter-btn{
        display: none;
        justify-content: flex-end;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            display: flex;
        }
    
        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            display: flex;
        }
        .filter-btn{
            padding:10px  40px ;
            background-color: $green;
            color: white;
            font-weight: 600;
            border: none;
            border-radius:5px ;
        }
    }

    .left {
        flex: 1;
        position: sticky;
        top: 120px;
        height: fit-content;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            display: none;
        }
    
        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            display: none;
        }
        
    }

    .right {
        flex: 6;

        .toy-grid {
            gap: 20px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            @media screen and (min-width: 300px) and (max-width: 480px) {
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            }
        
            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            }
            .product-name{
                @media screen and (min-width: 300px) and (max-width: 480px) {
                }
            }
           
        }
    }

}

.filter-container {
    .title-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .title{
            margin-bottom: 0;
        }
        .reset{
            color: #f94a4a;
            cursor: pointer;
        }
    }
    .filter-content {
        background-color: #EFF4FF;
        padding: 20px;
        border-radius: 5px;
        max-height: 600px;
        overflow-y:scroll ;

        form {
            .subtitle {
                font-size: 16px;
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                cursor: pointer;
            }

            .form-content {
                margin-top: 10px;
                padding: 20px;
                background-color: white;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                border-radius: 5px;
            }

            .MuiInputBase-root {
                background: white;
            }

            input[type=submit] {
                padding: 10px 20px;
                background-color: $green;
                color: white;
                margin-top: 30px;
                border: 0;
                border-radius: 5px;
                width: 100%;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
}

.pagination-container{
    display: flex;
    gap: 10px;
    align-items: center;
    .pages{
        height: 30px;
        width: 30px;
        color: rgb(209, 209, 209);
        display: grid;
        place-items: center;
        border-radius: 5px;
        cursor: pointer;
        &:hover{
            background-color: rgb(235, 235, 235);
        }
    }
    .current-page{
        background-color: $yellow;
        color: black;
    }
}

.prev-none{
    display: none;
}

.title-banner{
    height: 320px;
    width: 100%;
    display: grid;
    place-items: center;
    background-image: url('/public/images/image 9.png');
    font-size: 2rem;
    font-weight: 800;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        height: 180px;
        background-size: 100% 100% !important;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}
