$black:#262626;
$green:#4B9E99;
$yellow:#F9D54A;
$pink:#FFFAFE;


.checkout-container{
    display: flex;
    margin-top: 50px;
    gap: 40px;
    flex-wrap: wrap;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;

    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        flex-direction: column;
    }

    .left{
        flex: 1;
        form{
            .form-group-container{
                margin-bottom: 40px;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    }
    .right{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        height: fit-content;
        position: sticky;
        top: 20%;
    }
}

.subhead{
    color: $green;
    font-weight: 600;
}