$pink:#FFFAFE;
$green:#4B9E99;
$yellow:#F9D54A;
$darkgrey:#7E7E7E;
$black:#262626;

h5{
    margin: 0;
    margin-bottom: 10px;
}
button{
    border: none;
    cursor: pointer;
}
.yellow{
    color: $black;
}

.coming-soon{
    .img-container{
        height: 300px;
        width: 100%;
        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}

.title-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;

    }
}

.progress-bar {
    width: 100%; /* Full width of the parent container */
  }
  
  /* Media query for small screens like mobile devices */
  @media (max-width: 600px) {
    .progress-bar {
        width: 300px;
      /* Adjust styles for mobile here if needed */
    }
  }


.ctm-card{
    .card-title{
        font-weight: 600;
        font-size: 1.5rem;
    }
    .card-details{
        margin-top: 20px;
        .product-name{
            display: flex;
            flex-direction: column;
            
            .img-container{
                height: 100px;
                width: 100px;
                background-color: #4B9E99;
                border-radius: 5px;
                margin-bottom: 10px;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
        .customer-details{
            .detail{
                margin-top: 30px;
            }
            .title{
                color: $green;
                margin: 0;
            }
        }
        
    }
}

  
.date{
    display: flex;
    gap: 10px;
}