$pink:#FFFAFE;
$green:#4B9E99;
$yellow:#F9D54A;
$black:#262626;


.about-us-banner-section {
    display: flex;
    // background-image: url('/public/images/background.png');
    background-color: $pink;
    margin-top: 80px;
    max-height: 500px;
    align-items: center;
    gap: 30px;

    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column-reverse;
        max-height: none;
        align-items: flex-start;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        max-height: none;
    }

    .left {
        flex: 1;

        h1 {
            font-weight: 900;

            .black {
                font-size: 64px;
                color: $black;

            }

            .green-color {
                font-size: 96px;
                color: $green;

            }

            .yellow-color {
                font-size: 96px;
                color: $yellow;

            }

            @media screen and (min-width: 300px) and (max-width: 480px) {
                line-height: 64px;

                .black {
                    font-size: 40px;
                    color: $black;
                }

                .green-color {
                    font-size: 64px;
                    color: $green;

                }

                .yellow-color {
                    font-size: 64px;

                    color: $yellow;
                }
            }

            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                .black {
                    font-size: 40px;
                    color: $black;
                }

                .green-color {
                    font-size: 64px;
                    color: $green;
                }

                .yellow-color {
                    font-size: 64px;
                    color: $yellow;
                }

            }

        }
    }

    .right {
        flex: 1;

        @media screen and (min-width: 300px) and (max-width: 480px) {
            display: none;
        }

        .img-container {
            height: 600px;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

.about-us {
    display: flex;
    align-items: center;

    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {}

    .left {
        flex: 1;

        .img-container {
            height: 500px;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .right {
        flex: 1;

        .title {
            font-size: 1.5rem;
        }

        p {
            text-align: justify;
        }
    }
}

.why-us {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;

    .box-container {
        .title {
            font-size: 1.5rem;
        }

        .box {
            padding: 40px;
            border: 1px solid black;
            border-radius: 10px;
            min-height: none !important;
            width: 100%;
            height: unset;

            p {
                text-align: justify;
            }
        }
    }
}

.story-section {
    .story-container {
        display: flex;
        align-items: center;
        gap: 50px;
        padding: 40px;
        background-color: #EFF4FF;
        border-radius: 10px;

        @media screen and (min-width: 300px) and (max-width: 480px) {
            flex-direction: column;
        }

        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            flex-direction: column;
        }

        .left {
            flex: 1;

            .img-container {
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .right {
            flex: 1;

            p {
                text-align: justify;
            }
        }
    }
}

.our-story {
    .team {
        display: flex;
        align-items: center;
        gap: 50px;
        margin-bottom: 100px;

        @media screen and (min-width: 300px) and (max-width: 480px) {
            display: flex;
            flex-direction: column;
        }

        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            display: flex;
            flex-direction: column;
        }

        &:nth-child(odd) {
            flex-direction: row-reverse;
            gap: 50px;
            margin-bottom: 100px;

            @media screen and (min-width: 300px) and (max-width: 480px) {
                display: flex;
                flex-direction: column;
            }

            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                display: flex;
                flex-direction: column;
            }
        }

        .left {
            flex: 1;

            .year {
                font-size: 1.5rem;
                font-weight: bold;
                color: $green;
                align-self: flex-start;
            }

            .img-container {
                height: 300px;
                width: 500px;

                @media screen and (min-width: 300px) and (max-width: 480px) {
                    width: 100%;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .right {
            flex: 1;

            .name {
                margin-bottom: 30px;
                font-size: 2rem;
                color: #262626;
                font-weight: bold;
            }
        }
    }
}