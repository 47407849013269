$black:#262626;
$green:#4B9E99;
$yellow:#F9D54A;
$pink:#FFFAFE;

.cart-container{
    display: flex;
    gap: 30px;
    flex-direction: row-reverse;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column-reverse;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1300px) {
        flex-direction: column-reverse;
        
    }
    .left{
        flex: 2;
        height: fit-content;
        position: sticky;
        top: 20%;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            position: static;
        }
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            position: static;
        }
    }
    .right{
        flex: 4;
    }
}

.my-cart {
    margin-top: 40px;
}

.total-cart {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {

    }
    .small-text {
        margin-bottom: 10px;
    }

    .token {
        display: flex;
        align-items: center;
        gap: 20px;
        font-weight: 600;
        font-size: 2rem;
        color: $black;

        .icon-container {
            height: 50px;
            width: 50px;

            svg {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }


    }

    button {
        margin-top: 20px;
        width: 100%;
        background-color: $green;
        border: none;
        color: white;
        border-radius: 5px;
        padding: 10px 20px;
        font-weight: 600;
        cursor: pointer;
    }

}

.empty-cart{
    display: flex;
    padding: 30px;
    background-color: $yellow;
    position: relative;
    min-height: 500px;
    border-radius: 20px;
    align-items: center;
    background-image: url('/public/images/background.png');
    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        flex-direction: column;
    }
    .left{
        flex: 1;
        .img-container{
            height: 400px;
            width: 400px;
            position: absolute;
            bottom: -10%;
            @media screen and (min-width: 300px) and (max-width: 480px) {
                position: static;
                width: 200px;
                height: 100%;

            }
        
            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                position: static;
                height: 100%;
                width: 200px;
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;

            }
        }
    }
    .right{
        flex: 1;
        .content{
            font-size: 3rem;
            font-weight: 600;
            @media screen and (min-width: 300px) and (max-width: 480px) {
                font-size: 2rem;
                line-height: 2rem;
                margin-bottom: 10px;
            }
        
            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                font-size: 2rem;
                line-height: 2rem;
                margin-bottom: 10px;

            }
        }
    }
}