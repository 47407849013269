.cart-container {
  display: flex;
  gap: 30px;
  flex-direction: row-reverse;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .cart-container {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 481px) and (max-width: 1300px) {
  .cart-container {
    flex-direction: column-reverse;
  }
}
.cart-container .left {
  flex: 2;
  height: -moz-fit-content;
  height: fit-content;
  position: sticky;
  top: 20%;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .cart-container .left {
    position: static;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .cart-container .left {
    position: static;
  }
}
.cart-container .right {
  flex: 4;
}

.my-cart {
  margin-top: 40px;
}

.total-cart {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .total-cart {
    flex-direction: column;
  }
}
.total-cart .small-text {
  margin-bottom: 10px;
}
.total-cart .token {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 600;
  font-size: 2rem;
  color: #262626;
}
.total-cart .token .icon-container {
  height: 50px;
  width: 50px;
}
.total-cart .token .icon-container svg {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.total-cart button {
  margin-top: 20px;
  width: 100%;
  background-color: #4B9E99;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
}

.empty-cart {
  display: flex;
  padding: 30px;
  background-color: #F9D54A;
  position: relative;
  min-height: 500px;
  border-radius: 20px;
  align-items: center;
  background-image: url("/public/images/background.png");
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .empty-cart {
    flex-direction: column;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .empty-cart {
    flex-direction: column;
  }
}
.empty-cart .left {
  flex: 1;
}
.empty-cart .left .img-container {
  height: 400px;
  width: 400px;
  position: absolute;
  bottom: -10%;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .empty-cart .left .img-container {
    position: static;
    width: 200px;
    height: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .empty-cart .left .img-container {
    position: static;
    height: 100%;
    width: 200px;
  }
}
.empty-cart .left .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.empty-cart .right {
  flex: 1;
}
.empty-cart .right .content {
  font-size: 3rem;
  font-weight: 600;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .empty-cart .right .content {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .empty-cart .right .content {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 10px;
  }
}/*# sourceMappingURL=cart.css.map */