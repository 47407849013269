.store-banner-container {
  padding-top: 20px;
  position: relative;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .store-banner-container {
    margin-top: 80px !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .store-banner-container {
    margin-top: 80px !important;
  }
}
.store-banner-container .store-slide {
  background-color: #FFFAFE;
  padding: 20px 40px;
  display: flex !important;
  align-items: center;
  border-radius: 10px;
  gap: 40px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .store-banner-container .store-slide {
    flex-direction: column-reverse;
    padding: 20px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .store-banner-container .store-slide {
    flex-direction: column-reverse;
  }
}
.store-banner-container .store-slide .left {
  flex: 1;
}
.store-banner-container .store-slide .left .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.store-banner-container .store-slide .left .title h3 {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 800;
  line-height: 30px;
  text-transform: uppercase;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .store-banner-container .store-slide .left .title h3 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}
.store-banner-container .store-slide .left .title span {
  font-size: 1.2rem;
}
.store-banner-container .store-slide .left .btn-container {
  position: static;
}
.store-banner-container .store-slide .right {
  flex: 1;
}
.store-banner-container .store-slide .right .img-container {
  height: 500px;
  width: 100%;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .store-banner-container .store-slide .right .img-container {
    height: 300px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .store-banner-container .store-slide .right .img-container {
    height: 400px;
  }
}
.store-banner-container .store-slide .right .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.store-banner-container .prev {
  left: 0%;
}
.store-banner-container .next {
  right: 0%;
}

.store-container {
  display: flex;
  gap: 20px;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .store-container {
    flex-direction: column;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .store-container {
    flex-direction: column;
  }
}
.store-container .mobile-filter-btn {
  display: none;
  justify-content: flex-end;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .store-container .mobile-filter-btn {
    display: flex;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .store-container .mobile-filter-btn {
    display: flex;
  }
}
.store-container .mobile-filter-btn .filter-btn {
  padding: 10px 40px;
  background-color: #4B9E99;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}
.store-container .left {
  flex: 1;
  position: sticky;
  top: 120px;
  height: -moz-fit-content;
  height: fit-content;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .store-container .left {
    display: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .store-container .left {
    display: none;
  }
}
.store-container .right {
  flex: 6;
}
.store-container .right .toy-grid {
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .store-container .right .toy-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .store-container .right .toy-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
.filter-container .title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.filter-container .title-container .title {
  margin-bottom: 0;
}
.filter-container .title-container .reset {
  color: #f94a4a;
  cursor: pointer;
}
.filter-container .filter-content {
  background-color: #EFF4FF;
  padding: 20px;
  border-radius: 5px;
  max-height: 600px;
  overflow-y: scroll;
}
.filter-container .filter-content form .subtitle {
  font-size: 16px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
.filter-container .filter-content form .form-content {
  margin-top: 10px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.filter-container .filter-content form .MuiInputBase-root {
  background: white;
}
.filter-container .filter-content form input[type=submit] {
  padding: 10px 20px;
  background-color: #4B9E99;
  color: white;
  margin-top: 30px;
  border: 0;
  border-radius: 5px;
  width: 100%;
  font-weight: 600;
  cursor: pointer;
}

.pagination-container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.pagination-container .pages {
  height: 30px;
  width: 30px;
  color: rgb(209, 209, 209);
  display: grid;
  place-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.pagination-container .pages:hover {
  background-color: rgb(235, 235, 235);
}
.pagination-container .current-page {
  background-color: #F9D54A;
  color: black;
}

.prev-none {
  display: none;
}

.title-banner {
  height: 320px;
  width: 100%;
  display: grid;
  place-items: center;
  background-image: url("/public/images/image 9.png");
  font-size: 2rem;
  font-weight: 800;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .title-banner {
    height: 180px;
    background-size: 100% 100% !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .title-banner {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}/*# sourceMappingURL=store.css.map */