.btn-container {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 40px;
  bottom: 20px;
  cursor: pointer;
}
.btn-container .rent-now {
  display: flex;
  align-items: center;
  background-color: #4B9E99;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 10px;
  border-radius: 5px;
  position: relative;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .btn-container .rent-now {
    padding: 8px;
  }
}
.btn-container .rent-now .icon-container {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .btn-container .rent-now .icon-container {
    height: 15px;
    width: 15px;
  }
}
.btn-container .rent-now .icon-container svg {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.btn-container .rent-now span {
  color: white;
  font-weight: 700;
  font-size: 0.8rem;
}
.btn-container .add-to-cart {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #F6D64B;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .btn-container .add-to-cart {
    padding: 8px;
  }
}
.btn-container .add-to-cart span {
  color: #262626;
  font-weight: 700;
  font-size: 0.8rem;
}
.btn-container .red {
  background: rgba(183, 33, 38, 0.13);
  border-radius: 5px;
  border: 1px solid #B72126;
}
.btn-container .red span {
  font-weight: normal !important;
  color: #B72126 !important;
}
.btn-container .whatsapp {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  background-color: #25D366;
  color: white;
}
.btn-container .whatsapp .icon-container {
  height: 20px;
  width: 20px;
}
.btn-container .whatsapp .icon-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.btn-container .out-of-stock {
  background: rgba(183, 33, 38, 0.13);
  border-radius: 5px;
  border: 1px solid #B72126;
  padding: 12px 10px;
  border-radius: 5px;
  cursor: default;
}
.btn-container .out-of-stock span {
  font-weight: normal !important;
  color: #B72126 !important;
}

.button {
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  cursor: pointer;
}

.green {
  background-color: #4B9E99;
}

.yellow {
  background-color: #F6D64B;
}/*# sourceMappingURL=button.css.map */