$black:#262626;
$green:#4B9E99;
$yellow:#F9D54A;
$pink:#FFFAFE;

.subscription-card {
    padding: 20px;
    background-color: #EFF4FF;
    border-radius: 10px;
    width: 100%;
    // max-width: 365px;
    .title-container{
        display: flex;
        justify-content: space-between;
    }
    .subscription-title {
        display: flex;
        align-items: center;
        gap: 20px;

        .icon-container {
            height: 50px;
            width: 50px;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        .name {
            font-weight: 800;
            font-size: 1.2rem;
        }
    }

    .description {
        margin-top: 20px;
    }

    .cost {
        margin-top: 20px;
        font-size: 3.375rem;
        font-weight: 800;
        color: $black;

        span {
            font-size: 1.2rem;
            font-family: 600;
        }
    }

    .features-container {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .feature {
            display: flex;
            justify-content: space-between;
           
            .detail {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }
    }

    .whatsapp{
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 10px;
        background-color: #25D366;
        color: white;
        .icon-container{
            height: 20px;
            width: 20px;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .btn {
        margin-top: 40px;
        padding: 10px 20px;
        background-color: $green;
        color: white;
        font-weight: 600;
        text-align: center;
        border-radius: 5px;
    }
}