.subscription-container {
  padding-top: 50px;
}
.subscription-container .grid-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .subscription-container .grid-container {
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .subscription-container .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}

.exhausted-credit-container {
  background-color: #F9D54A;
  padding: 20px 40px !important;
  display: flex;
  gap: 50px;
  align-items: center;
  border-radius: 10px;
}
.exhausted-credit-container .left .img-container {
  height: 300px;
  width: 100%;
}
.exhausted-credit-container .left .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.exhausted-credit-container .right {
  flex: 1;
}
.exhausted-credit-container .right .content {
  flex: 2;
}
.exhausted-credit-container .right .content h3 {
  font-size: 2.5rem;
  font-weight: 400;
}
.exhausted-credit-container .right form {
  flex: 1;
}
.exhausted-credit-container .right form .form-group-container .formgroup {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.exhausted-credit-container .right form .form-group-container .formgroup .button {
  padding: 5px 5px;
  border: 1px solid #262626;
  border-radius: 5px;
  color: #262626;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
}
.exhausted-credit-container .right form .form-group-container .formgroup input[type=submit] {
  background-color: #4B9E99;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.cart-wallet {
  gap: 40px;
  display: flex;
}/*# sourceMappingURL=pricing.css.map */