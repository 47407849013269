$pink:#FFFAFE;
$green:#4B9E99;
$yellow:#F9D54A;
$black:#262626;


.flex-direction{
    display: flex;
    flex-direction: column;
    align-items: flex-start!important;
    justify-content: flex-start;
}
.login-container{
    display: flex;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        flex-direction: column;
    }
    .left{
        flex: 1;
        background-color: #709E4B;
        min-height: 100vh;
        padding: 50px 0;
        position: relative;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            display: none;
        }
    
        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            display: none;
        }
        .logo-container{
            width: 400px;
            margin: auto;

            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        .img-container{
            height: auto;
            width: 105%;
            position: absolute;
            bottom: -5%;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
    .right{
        flex: 1;
        margin: auto;
        .logo-container{
            width: 100%;
            display: none;
            @media screen and (min-width: 300px) and (max-width: 480px) {
                display: block;
            }
        
            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                display: block;
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        @media screen and (min-width: 300px) and (max-width: 480px) {
            min-height: 100vh;
            display: grid;
            place-items: center;
        }
    
        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            min-height: 100vh;
            display: grid;
            place-items: center;

        }
        
        

        .form{
            margin: auto;
            width: 300px;
            padding: 100px 0;
            .username{
                text-transform: lowercase!important;
            }
            .error{
                padding: 20px;
                background-color: rgba(183, 33, 38, 0.13);
                color: #B72126;
                border: 1px solid #B72126;
                border-radius: 5px;
                margin: 40px 0;
            }
            .form-group-container{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .form-group{
                    display: flex;
                    gap: 20px;
                }
            }
        }
    }

}


.registeration-container{
    display: flex;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        flex-direction: column;
    }
    .left{
        flex: 1;
        background-color: #709E4B;
        min-height: 100vh;
        padding: 50px 0;
        position: relative;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            display: none;
        }
    
        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            display: none;
        }
        .logo-container{
            width: 400px;
            margin: auto;

            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        .img-container{
            height: auto;
            width: 105%;
            position: absolute;
            bottom: -5%;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
    .right{
        flex: 1;
        margin: auto;
        .logo-container{
            width: 100%;
            display: none;
            @media screen and (min-width: 300px) and (max-width: 480px) {
                display: block;
            }
        
            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                display: block;
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        @media screen and (min-width: 300px) and (max-width: 480px) {
            min-height: 100vh;
            display: grid;
            place-items: center;
        }
    
        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            min-height: 100vh;
            display: grid;
            place-items: center;

        }
        
        

        .form{
            margin: auto;
            width: 100%;
            padding: 100px ;
            .error{
                padding: 20px;
                background-color: rgba(183, 33, 38, 0.13);
                color: #B72126;
                border: 1px solid #B72126;
                border-radius: 5px;
                margin: 40px 0;
            }
            .form-group-container{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .form-group{
                    display: flex;
                    gap: 20px;
                }
            }
        }
    }
}