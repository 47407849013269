.cart-card {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
}
@media screen and (min-width: 300px) and (max-width: 800px) {
  .cart-card {
    flex-direction: column;
    align-items: start;
    display: none;
  }
}
.cart-card .cart-img-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .cart-card .cart-img-container {
    align-items: start;
    flex-direction: column;
  }
}
.cart-card .cart-card-img {
  padding: 10px;
  background-color: #EFF4FF;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
}
.cart-card .cart-card-img .img-container {
  height: 100px;
  width: 100px;
}
.cart-card .cart-card-img .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.cart-card .cart-name {
  font-weight: 600;
  font-size: 0.8rem;
  width: 200px;
}
.cart-card .cart-start-date span {
  color: #4B9E99;
  font-size: 0.8rem;
}
.cart-card .cart-start-date .MuiInputBase-root {
  border: none !important;
  background-color: #E7E7E7 !important;
}
.cart-card .cart-start-date .MuiOutlinedInput-notchedOutline {
  border: 0;
}
.cart-card .cart-start-date input {
  padding: 10px;
  border: none !important;
  background-color: #E7E7E7 !important;
  width: 100px;
}
.cart-card .counter {
  display: flex;
  align-items: flex-end;
}
.cart-card .counter .button {
  height: 30px;
  width: 30px;
  text-align: center;
  display: grid;
  place-items: center;
  border-radius: 5px;
  background-color: #4B9E99;
  color: white;
  margin: 0 10px;
  cursor: pointer;
}
.cart-card .counter input {
  padding: 10px;
  border: none;
  background-color: #E7E7E7;
  width: 100px;
  text-align: center;
}
.cart-card .price-container .price {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cart-card .price-container .price .icon-container {
  height: 30px;
  width: 30px;
}
.cart-card .price-container .price .icon-container svg {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.cart-card .Disable {
  color: #B72126;
  background: rgba(183, 33, 38, 0.13);
  border-radius: 5px;
  border: 1px solid #B72126;
  padding: 5px 10px;
}

.add-button, .minus-button {
  display: grid;
  place-items: center;
  padding: 0;
}

.mobile-cart-card {
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgb(207, 207, 207);
  position: relative;
  margin-bottom: 20px;
}
@media screen and (min-width: 300px) and (max-width: 800px) {
  .mobile-cart-card {
    display: flex;
  }
}
.mobile-cart-card .cart-img-container {
  padding: 20px;
  background-color: #EFF4FF;
  border-radius: 10px;
}
.mobile-cart-card .cart-img-container .img-container {
  height: 100px;
  width: 100%;
}
.mobile-cart-card .cart-img-container .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.mobile-cart-card .cart-name {
  margin-top: 20px;
  font-size: 12px;
}
.mobile-cart-card .remove {
  position: absolute;
  top: 20px;
  right: 20px;
}
.mobile-cart-card .date-counter-container {
  display: flex;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap;
}
.mobile-cart-card .date-counter-container .cart-start-date span {
  color: #4B9E99;
  font-size: 0.5rem;
}
.mobile-cart-card .date-counter-container .cart-start-date .MuiInputBase-root {
  border: none !important;
  background-color: #E7E7E7 !important;
}
.mobile-cart-card .date-counter-container .cart-start-date .MuiOutlinedInput-notchedOutline {
  border: 0;
}
.mobile-cart-card .date-counter-container .cart-start-date .mui-datepicker {
  --mui-datepicker-font-size: 12px;
}
.mobile-cart-card .date-counter-container .cart-start-date input {
  padding: 5px;
  border: none !important;
  background-color: #E7E7E7 !important;
  width: 100px;
  font-size: 12px;
}
.mobile-cart-card .date-counter-container .counter {
  display: flex;
  align-items: flex-end;
}
.mobile-cart-card .date-counter-container .counter .button {
  height: 20px;
  width: 20px;
  text-align: center;
  display: grid;
  place-items: center;
  border-radius: 5px;
  background-color: #4B9E99;
  color: white;
  margin: 0 10px;
  cursor: pointer;
}
.mobile-cart-card .date-counter-container .counter input {
  padding: 5px;
  border: none;
  background-color: #E7E7E7;
  width: 100px;
  text-align: center;
  font-size: 12px;
}
.mobile-cart-card .price-container {
  display: flex;
  margin-top: 20px;
  justify-content: start;
  align-self: start;
  align-items: center;
  gap: 20px;
}
.mobile-cart-card .price-container .small-text {
  font-weight: 600;
}
.mobile-cart-card .price-container .price {
  display: flex;
  align-items: center;
  gap: 10px;
}

.out-of-stock-btn {
  position: absolute;
  top: -5%;
  left: 0%;
}/*# sourceMappingURL=cart-card.css.map */