$pink:#FFFAFE;
$green:#4B9E99;
$yellow:#F9D54A;


.toy-detail-container {
    display: flex;
    gap: 40px;
    // margin-top: 0;
    padding-top: 50px;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
        margin-top: 100px;

    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        flex-direction: column;
        margin-top: 150px;
    }
    .left {
        flex: 1;

        .main-img {
            height: 500px;
            width: 500px;
            position: relative;
            background-color: #FFFAFE;
            padding: 40px;
            border-radius: 5px;
            border: 1px solid #D3D3D3;
            @media screen and (min-width: 300px) and (max-width: 480px) {
                width: 100%;
                height: 300px;                
            }
            .toy-img {
                top: 40px;
                left: 40px;
                right: 40px;
                bottom: 40px;

                position: absolute;

                .img-container {
                    height: 100%;
                    width: 100%;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
                .icon-container{
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
                .filled{
                    
                    svg{
                        path{
                            fill: #F54C4C;
                        }
                    }
                }
            }
        }

        .img-nav-container {
            display: flex;
            margin-top: 40px;

            .img-nav {
                padding: 10px;
                margin-right: 20px;
                background-color: $pink;
                border-radius: 5px;
                border: 1px solid #D3D3D3;
                
                .img-container {
                    height: 50px;
                    width: 50px;
                    cursor: pointer;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }
            .selected{
                border: 1px solid $green;
            }
        }
    }

    .right {
        flex: 2;

        .product-title {
            h1 {
                font-size: 2rem;
                font-weight: 800;
                text-transform: none;
                margin: 0;
                @media screen and (min-width: 300px) and (max-width: 480px) {
                    line-height: 50px;
                }
        
                // iPads, Tablets
                @media screen and (min-width: 481px) and (max-width: 1200px) {
                    line-height: 50px;
                }
            }
        }

        .product-detail-container {
            .product-detail {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                .detail {
                    color: $green;
                    font-size: 0.8rem;
                }
            }
        }
    }

}

.product-description{
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 600;
    color: rgb(212, 154, 52);
    .icon-container{
    }
}