footer {
    background-color: #f3f3f3;
    padding: 40px;
    margin-top: 150px;

    .footer-container {
        display: flex;

        @media screen and (min-width: 300px) and (max-width: 480px) {
            flex-direction: column;
        }

        .left {
            flex: 1;

            .logo-container {
                width: 200px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .right {
            flex: 3;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            ul {
                list-style: none;

                .heading {
                    font-size: 1.5rem;
                    font-weight: 400;
                    margin-bottom: 10px;
                }

                li {
                    padding-bottom: 10px;
                }
            }
        }
    }
}