$primary:    #4B9E99;
$secondary:  #F9D54A;

.banner-section{
    position: relative;
    display: flex;
    // display: none;
    // margin-top: 60px;
    background-color: #ECFFDD;
    border-radius: 10px;
    min-height:60vh;
    align-items: center;
    // background-image:url('/public/images/background-5.png') ;
    background-size: contain;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
        padding-top:30px ;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        flex-direction: column;
    }
    .left{
        flex: 2;
        span{
            font-size: 1.8rem;
            color: $primary;
        }
        h1{
            font-size:2.5rem;
            text-transform: none;
            line-height: 4rem;
            margin: 0;
            margin-bottom: 10px;
        }
        p{
        }

    }

    .right{
        flex: 2;
        position: relative;
        min-height: 60vh;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            min-height: unset;

        }
    
        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            min-height: unset;
        }
        .img-container{
            height: 120%;
            width: 100%;
            position: absolute;
            top: 0;
            @media screen and (min-width: 300px) and (max-width: 480px) {
                position: relative;
                height: unset;
                width: 100%;
            }
        
            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                position: relative;
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

.mobile-banner{
    display: none;
     @media screen and (min-width: 300px) and (max-width: 480px) {
        display: none;
    }

    
}
.slider-wrapper{
    position: relative;
}
.toy-categories-slider{
    
    @media screen and (min-width: 300px) and (max-width: 480px) {
        margin-top: 50px;
    }
}
.about-us-container{
    margin-top: 0;
    .about-us-section{
        background-color: #ECFFFE;
        background-image: url('/public/images/background-3.png');
        border-radius: 10px;
        margin-top: 10px;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        display: flex;
        background-repeat: no-repeat;
        position: relative;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            flex-direction: column;
        }
        .left{
            flex: 5;
            display: flex;
            padding: 100px;
            gap: 50px;
            align-items: center;
            @media screen and (min-width: 300px) and (max-width: 480px) {
                padding: 40px;
                flex-direction: column;
                gap: 30px;
            }
            .icon-container{
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: unset!important;
            
                .img-container{
                    height: 100px;
                    width: 100px;
                    margin-bottom: 10px;
                    @media screen and (min-width: 300px) and (max-width: 480px) {
                        height: 80px;
                        width: 80px;
                    }
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
                .name{
                    font-size: 1.5rem;
                }
                p{
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
        .right{
            flex: 2;
           
            .img-container{
                height: 400px;
                width: 100%;
                @media screen and (min-width: 300px) and (max-width: 480px) {
                    height: 200px;
                }
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
        .star{
            position: absolute;
            bottom: 40px;
            left: 40px;
            height: 100px;
            width: 100px;
            z-index: 4;
            display: none;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}


.best-section {
    .best-container {
        background-color: $secondary;
        border-radius: 10px;
        margin-top: 10px;
        overflow: hidden;

        .left {
            flex: 1;
            padding: 40px;

            h1 {
                font-size: 3rem;
                text-transform: none;
                line-height: 3rem;
            }

        }

        .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            transform: rotate(0deg);

            .top {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .title {
                    padding: 0px 40px;
                    margin: 0;
                    font-size: 2rem;
                    @media screen and (min-width: 300px) and (max-width: 480px) {
                        padding: 0 50px;
                    }
                }

                .marquee-container {
                    display: flex;
                    gap: 20px;
                    animation: scrollingY 30s linear infinite;
                    &:hover {
                        animation-play-state: paused;
                      }
                    .box-1 {
                        padding: 20px;
                        background-color: $primary;
                        border-radius: 5px;

                        .logo-container {
                            height: 100px;
                            width: 100px;

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }

            .down {

                display: flex;
                flex-direction: column;

                .title {
                    padding: 0px 40px;
                    margin: 0;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    @media screen and (min-width: 300px) and (max-width: 480px) {
                        padding: 0 50px;
                    }
                }

                .marquee-container {
                    display: flex;
                    gap: 20px;
                    animation: scrollingX 30s linear infinite;


                    .box-1 {
                        padding: 20px;
                        background-color: white;
                        border-radius: 5px;
                        border: 1px solid black;

                        .logo-container {
                            height: 100px;
                            width: 100px;

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes scrollingX {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translatex(-144vw);
    }
}

@keyframes scrollingY {
    0% {
        transform: translateX(-144vw);
    }

    100% {
        transform: translatex(0);
    }
}

.faq-section {
    display: flex;

    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {}

    .left {
        flex: 1;

        h2 {
            font-weight: 600;
            text-transform: none;
        }

        h4 {
            font-weight: 600;
            margin-bottom: 20px;
        }

    }

    .right {
        flex: 1;
        
    }
}
.faq-row-wrapper {
    .row-title {
        padding: 20px !important;
    }

    .faq-row {
        margin-bottom: 10px;
        border-radius: 10px;
        border: 0 !important;
        background-color: #FFFAFE;
    }

    .icon-wrapper {
        height: 30px;
        width: 30px;
        max-height: 40px !important;
        max-width: 40px !important;
        border-radius: 5px;
        background-color: $primary;
        text-align: center;
        display: grid;
        place-items: center;
        color: white !important;
    }

    section {
        margin-top: 0;
    }
}

.pagination-container {
    display: flex;
    justify-content: flex-end;
}

.icon-container{
    height: 30px;
    width: 30px;
    cursor: pointer;
    svg{
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.popular-card-grid{
   
}

.loader-animation {
    position: fixed; 
    z-index: 9999; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    background: white; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    display: flex;
    flex-direction: column;
    align-items: center;
}


.banner-container{
    .img-container{
        height: 600px;
        width: 100%;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            height: unset;
        }

        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            height: unset;
        }
        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}

.img-1{
    position: absolute;
    z-index: 100;
    height: 200px;
    width: 200px;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        display: none;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        display: none;
    }
    img{
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.contact-us-section {

    .contact-us-container {
        display: flex;
        gap: 50px;

        // Mobile devices
        @media screen and (min-width: 300px) and (max-width: 480px) {
            flex-direction: column;
        }
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            flex-direction: column;
        }
        .left {
            flex: 1;


            .content {

                // Mobile devices
                @media screen and (min-width: 300px) and (max-width: 480px) {
                    margin-top: 20px;
                }

                // iPads, Tablets
                @media screen and (min-width: 481px) and (max-width: 820px) {
                    margin-right: 0px;

                }

                .icon-text-container {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    margin-top: 50px;

                    .icon-text {
                        .icon-container {
                            height: 30px;
                            width: 30px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        .right {
            flex: 2;

            iframe {
                height: 600px;
                width: 100%;

                // Mobile devices
                @media screen and (min-width: 300px) and (max-width: 480px) {
                    height: 300px;
                }

                // iPads, Tablets
                @media screen and (min-width: 481px) and (max-width: 820px) {
                    height: 530px;
                }
            }
        }
    }
}
