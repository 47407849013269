h5 {
  margin: 0;
  margin-bottom: 10px;
}

button {
  border: none;
  cursor: pointer;
}

.yellow {
  color: #262626;
}

.coming-soon .img-container {
  height: 300px;
  width: 100%;
}
.coming-soon .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .title-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .title-container {
    flex-direction: column;
    align-items: flex-start;
  }
}

.progress-bar {
  width: 100%; /* Full width of the parent container */
}

/* Media query for small screens like mobile devices */
@media (max-width: 600px) {
  .progress-bar {
    width: 300px;
    /* Adjust styles for mobile here if needed */
  }
}
.ctm-card .card-title {
  font-weight: 600;
  font-size: 1.5rem;
}
.ctm-card .card-details {
  margin-top: 20px;
}
.ctm-card .card-details .product-name {
  display: flex;
  flex-direction: column;
}
.ctm-card .card-details .product-name .img-container {
  height: 100px;
  width: 100px;
  background-color: #4B9E99;
  border-radius: 5px;
  margin-bottom: 10px;
}
.ctm-card .card-details .product-name .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.ctm-card .card-details .customer-details .detail {
  margin-top: 30px;
}
.ctm-card .card-details .customer-details .title {
  color: #4B9E99;
  margin: 0;
}

.date {
  display: flex;
  gap: 10px;
}/*# sourceMappingURL=common.css.map */