$black:#262626;
$green:#4B9E99;
$yellow:#F9D54A;
$pink:#FFFAFE;


.my-cart {
    
}

.cart-card {
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 40px;
    padding-top: 40px;
    position: relative;

    @media screen and (min-width: 300px) and (max-width: 800px) {
        flex-direction: column;
        align-items: start;
        display: none;
    }

    .cart-img-container{
        display: flex;
        align-items: center;
        gap: 20px;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            align-items: start;
            flex-direction: column;
        }
    }
    .cart-card-img {
        padding: 10px;
        background-color: #EFF4FF;
        border-radius: 5px;
        width: fit-content;
        .img-container {
            height: 100px;
            width: 100px;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .cart-name {
        font-weight: 600;
        font-size: 0.8rem;
        width: 200px;
    }

    .cart-start-date {
        span {
            color: $green;
            font-size: 0.8rem;
        }

        .MuiInputBase-root {
            border: none !important;
            background-color: #E7E7E7 !important;

        }

        .MuiOutlinedInput-notchedOutline {
            border: 0;
        }

        input {
            padding: 10px;
            border: none !important;
            background-color: #E7E7E7 !important;
            width: 100px;
        }
    }

    .counter {
        display: flex;
        align-items: flex-end;

        .button {
            height: 30px;
            width: 30px;
            text-align: center;
            display: grid;
            place-items: center;
            border-radius: 5px;
            background-color: $green;
            color: white;
            margin: 0 10px;
            cursor: pointer;
        }

        input {
            padding: 10px;
            border: none;
            background-color: #E7E7E7;
            width: 100px;
            text-align: center;
        }
    }

    .price-container {
        .price {
            display: flex;
            align-items: center;
            gap: 10px;

            .icon-container {
                height: 30px;
                width: 30px;

                svg {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    .Disable{
        color: #B72126;
        background: rgba(183, 33, 38, 0.13);
        border-radius: 5px;
        border: 1px solid #B72126;
        padding: 5px 10px;
    }
}
.add-button,.minus-button{
    display: grid;
    place-items: center;
    padding: 0;
}

.mobile-cart-card{
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgb(207, 207, 207);
    // border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
    @media screen and (min-width: 300px) and (max-width: 800px) {
        display: flex;
        
    }
    .cart-img-container{
        padding: 20px;
        background-color: #EFF4FF;

        border-radius: 10px;
        .img-container{
            height: 100px;
            width: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
    .cart-name{
        margin-top: 20px;
        font-size: 12px;
    }
    .remove{
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .date-counter-container{
        display: flex;
        margin-top: 20px;
        gap: 20px;
        flex-wrap: wrap;
        .cart-start-date {
            span {
                color: $green;
                font-size: 0.5rem;
            }
    
            .MuiInputBase-root {
                border: none !important;
                background-color: #E7E7E7 !important;
    
            }
    
            .MuiOutlinedInput-notchedOutline {
                border: 0;
            }
            .mui-datepicker {
                --mui-datepicker-font-size: 12px;
              }
            input {
                padding: 5px;
                border: none !important;
                background-color: #E7E7E7 !important;
                width: 100px;
                font-size: 12px;
            }
        }
    
        .counter {
            display: flex;
            align-items: flex-end;
    
            .button {
                height: 20px;
                width: 20px;
                text-align: center;
                display: grid;
                place-items: center;
                border-radius: 5px;
                background-color: $green;
                color: white;
                margin: 0 10px;
                cursor: pointer;
            }
    
            input {
                padding: 5px;
                border: none;
                background-color: #E7E7E7;
                width: 100px;
                text-align: center;
                font-size: 12px;
            }
        }
    }
    .price-container{
        display: flex;
        margin-top: 20px;
        justify-content: start;
        align-self: start;
        align-items: center;
        gap: 20px;
        .small-text{
            font-weight: 600;
        }
        .price{
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}
.out-of-stock-btn{
    position: absolute;
    top: -5%;
    left: 0%;   
}