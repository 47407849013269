$primary:    #4B9E99;
$secondary:  #F9D54A;

.category-card {
    padding: 30px 20px;
    border-radius: 5px;
    transition: all 0.5s ease;
    display: grid;
    place-items: center;
    min-height: 280px;
    background-color: #f9d64a31;
    transition: all 0.5s ease;

    &:hover{
        .category-img{
            transform: scale(1.1);
        }
    }
    @media screen and (min-width: 300px) and (max-width: 480px) {
        padding: 20px 5px;
        min-height: 180px;
    }

    .category-img {
        height: 150px;
        width: 100%;
        margin: auto;
        transition: all 0.5s ease;
        @media screen and (min-width: 300px) and (max-width: 480px) {
            height: 80px;
            width: 100%;
            margin: auto;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .category-name {
        margin-top: 10px;
        text-align: center;
        font-size: 0.8rem;
        font-weight: 700;
    }

   
}

