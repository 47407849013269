footer {
  background-color: #f3f3f3;
  padding: 40px;
  margin-top: 150px;
}
footer .footer-container {
  display: flex;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  footer .footer-container {
    flex-direction: column;
  }
}
footer .footer-container .left {
  flex: 1;
}
footer .footer-container .left .logo-container {
  width: 200px;
}
footer .footer-container .left .logo-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
footer .footer-container .right {
  flex: 3;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
footer .footer-container .right ul {
  list-style: none;
}
footer .footer-container .right ul .heading {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 10px;
}
footer .footer-container .right ul li {
  padding-bottom: 10px;
}/*# sourceMappingURL=footer.css.map */