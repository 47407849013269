.checkout-container {
  display: flex;
  margin-top: 50px;
  gap: 40px;
  flex-wrap: wrap;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .checkout-container {
    flex-direction: column;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .checkout-container {
    flex-direction: column;
  }
}
.checkout-container .left {
  flex: 1;
}
.checkout-container .left form .form-group-container {
  margin-bottom: 40px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.checkout-container .right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  height: -moz-fit-content;
  height: fit-content;
  position: sticky;
  top: 20%;
}

.subhead {
  color: #4B9E99;
  font-weight: 600;
}/*# sourceMappingURL=checkout.css.map */