.subscription-card {
  padding: 20px;
  background-color: #EFF4FF;
  border-radius: 10px;
  width: 100%;
}
.subscription-card .title-container {
  display: flex;
  justify-content: space-between;
}
.subscription-card .subscription-title {
  display: flex;
  align-items: center;
  gap: 20px;
}
.subscription-card .subscription-title .icon-container {
  height: 50px;
  width: 50px;
}
.subscription-card .subscription-title .icon-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.subscription-card .subscription-title .name {
  font-weight: 800;
  font-size: 1.2rem;
}
.subscription-card .description {
  margin-top: 20px;
}
.subscription-card .cost {
  margin-top: 20px;
  font-size: 3.375rem;
  font-weight: 800;
  color: #262626;
}
.subscription-card .cost span {
  font-size: 1.2rem;
  font-family: 600;
}
.subscription-card .features-container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subscription-card .features-container .feature {
  display: flex;
  justify-content: space-between;
}
.subscription-card .features-container .feature .detail {
  display: flex;
  gap: 10px;
  align-items: center;
}
.subscription-card .whatsapp {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  background-color: #25D366;
  color: white;
}
.subscription-card .whatsapp .icon-container {
  height: 20px;
  width: 20px;
}
.subscription-card .whatsapp .icon-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.subscription-card .btn {
  margin-top: 40px;
  padding: 10px 20px;
  background-color: #4B9E99;
  color: white;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
}/*# sourceMappingURL=subscription-card.css.map */