.case-studies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 10px;
  margin-top: 20px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .case-studies-grid {
    flex-direction: column;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .case-studies-grid {
    grid-template-columns: 1fr 1fr;
  }
}
.case-studies-grid .case {
  border: 1px solid #B9B9B9;
  border-radius: 5px;
  min-height: 400px;
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .case-studies-grid .case {
    min-height: 200px;
  }
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .case-studies-grid .case {
    min-height: 200px;
  }
}
.case-studies-grid .case .content {
  padding: 30px;
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .case-studies-grid .case .content {
    padding: 20px;
  }
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .case-studies-grid .case .content {
    padding: 20px;
  }
}
.case-studies-grid .case .content .case-img {
  height: 200px;
  width: 100%;
}
.case-studies-grid .case .content .case-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.case-studies-grid .case .content .case-title {
  margin-top: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: justify;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .case-studies-grid .case .content .case-title {
    margin-top: 20px;
  }
}
.case-studies-grid .case .content .date {
  color: #4B9E99;
  margin-top: 10px;
  font-size: 0.8rem;
  display: none;
}
.case-studies-grid .case .content .short-description {
  margin-top: 20px;
  text-align: justify;
  font-size: 0.8rem;
}
.case-studies-grid .case .content .tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 5px;
}
.case-studies-grid .case .content .tag-container .tag {
  padding: 5px 5px;
  background-color: #EBEBEB;
  font-size: 0.8rem;
  border-radius: 5px;
  color: #B9B9B9;
  display: none;
}
.case-studies-grid .case .content a .readmore {
  margin-top: 30px;
  text-decoration: underline !important;
  color: #4B9E99;
  font-weight: 700;
  letter-spacing: 0.32rem;
  font-size: 1rem;
}

@media screen and (min-width: 481px) and (max-width: 1200px) {
  .case-detail-container {
    padding-top: 0;
  }
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .case-detail-container .right {
    display: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .case-detail-container .right {
    display: none;
  }
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .case-detail-container .left {
    border: 0;
    padding: 0;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .case-detail-container .left {
    border: 0;
    padding: 0;
  }
}

.case-study-detail .case-study-banner {
  width: 100%;
}
.case-study-detail .case-study-banner img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.case-study-detail .case-study-title-container {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .case-study-detail .case-study-title-container {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
  }
}
.case-study-detail .case-study-title-container .case-study-title h1 {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 40px;
  margin: 0;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .case-study-detail .case-study-title-container .case-study-title h1 {
    font-size: 1.8rem;
  }
}
.case-study-detail .case-study-title-container .case-study-title .subtitle h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #F9D54A;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .case-study-detail .case-study-title-container .case-study-title .subtitle h2 {
    font-size: 1.2rem;
    line-height: 28px;
  }
}
.case-study-detail .case-study-title-container .case-study-title .tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 5px;
}
.case-study-detail .case-study-title-container .case-study-title .tag-container .tag {
  padding: 5px 5px;
  background-color: #EBEBEB;
  font-size: 0.8rem;
  border-radius: 5px;
  color: #B9B9B9;
}
.case-study-detail .case-study-title-container .date {
  color: #4B9E99;
  display: none;
}
.case-study-detail .case-study {
  margin-top: 50px;
}
.case-study-detail .case-study .section {
  margin-bottom: 50px;
}
.case-study-detail .case-study .section .heading {
  text-transform: uppercase;
  letter-spacing: 0.32em;
  font-weight: 700;
  color: #4B9E99;
}/*# sourceMappingURL=blogs.css.map */