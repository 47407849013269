.banner-section {
  position: relative;
  display: flex;
  background-color: #ECFFDD;
  border-radius: 10px;
  min-height: 60vh;
  align-items: center;
  background-size: contain;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .banner-section {
    flex-direction: column;
    padding-top: 30px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .banner-section {
    flex-direction: column;
  }
}
.banner-section .left {
  flex: 2;
}
.banner-section .left span {
  font-size: 1.8rem;
  color: #4B9E99;
}
.banner-section .left h1 {
  font-size: 2.5rem;
  text-transform: none;
  line-height: 4rem;
  margin: 0;
  margin-bottom: 10px;
}
.banner-section .right {
  flex: 2;
  position: relative;
  min-height: 60vh;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .banner-section .right {
    min-height: unset;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .banner-section .right {
    min-height: unset;
  }
}
.banner-section .right .img-container {
  height: 120%;
  width: 100%;
  position: absolute;
  top: 0;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .banner-section .right .img-container {
    position: relative;
    height: unset;
    width: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .banner-section .right .img-container {
    position: relative;
  }
}
.banner-section .right .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.mobile-banner {
  display: none;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .mobile-banner {
    display: none;
  }
}

.slider-wrapper {
  position: relative;
}

@media screen and (min-width: 300px) and (max-width: 480px) {
  .toy-categories-slider {
    margin-top: 50px;
  }
}

.about-us-container {
  margin-top: 0;
}
.about-us-container .about-us-section {
  background-color: #ECFFFE;
  background-image: url("/public/images/background-3.png");
  border-radius: 10px;
  margin-top: 10px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  display: flex;
  background-repeat: no-repeat;
  position: relative;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .about-us-container .about-us-section {
    flex-direction: column;
  }
}
.about-us-container .about-us-section .left {
  flex: 5;
  display: flex;
  padding: 100px;
  gap: 50px;
  align-items: center;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .about-us-container .about-us-section .left {
    padding: 40px;
    flex-direction: column;
    gap: 30px;
  }
}
.about-us-container .about-us-section .left .icon-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: unset !important;
}
.about-us-container .about-us-section .left .icon-container .img-container {
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .about-us-container .about-us-section .left .icon-container .img-container {
    height: 80px;
    width: 80px;
  }
}
.about-us-container .about-us-section .left .icon-container .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.about-us-container .about-us-section .left .icon-container .name {
  font-size: 1.5rem;
}
.about-us-container .about-us-section .left .icon-container p {
  font-size: 14px;
  text-align: center;
}
.about-us-container .about-us-section .right {
  flex: 2;
}
.about-us-container .about-us-section .right .img-container {
  height: 400px;
  width: 100%;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .about-us-container .about-us-section .right .img-container {
    height: 200px;
  }
}
.about-us-container .about-us-section .right .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.about-us-container .about-us-section .star {
  position: absolute;
  bottom: 40px;
  left: 40px;
  height: 100px;
  width: 100px;
  z-index: 4;
  display: none;
}
.about-us-container .about-us-section .star img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.best-section .best-container {
  background-color: #F9D54A;
  border-radius: 10px;
  margin-top: 10px;
  overflow: hidden;
}
.best-section .best-container .left {
  flex: 1;
  padding: 40px;
}
.best-section .best-container .left h1 {
  font-size: 3rem;
  text-transform: none;
  line-height: 3rem;
}
.best-section .best-container .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transform: rotate(0deg);
}
.best-section .best-container .right .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.best-section .best-container .right .top .title {
  padding: 0px 40px;
  margin: 0;
  font-size: 2rem;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .best-section .best-container .right .top .title {
    padding: 0 50px;
  }
}
.best-section .best-container .right .top .marquee-container {
  display: flex;
  gap: 20px;
  animation: scrollingY 30s linear infinite;
}
.best-section .best-container .right .top .marquee-container:hover {
  animation-play-state: paused;
}
.best-section .best-container .right .top .marquee-container .box-1 {
  padding: 20px;
  background-color: #4B9E99;
  border-radius: 5px;
}
.best-section .best-container .right .top .marquee-container .box-1 .logo-container {
  height: 100px;
  width: 100px;
}
.best-section .best-container .right .top .marquee-container .box-1 .logo-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.best-section .best-container .right .down {
  display: flex;
  flex-direction: column;
}
.best-section .best-container .right .down .title {
  padding: 0px 40px;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .best-section .best-container .right .down .title {
    padding: 0 50px;
  }
}
.best-section .best-container .right .down .marquee-container {
  display: flex;
  gap: 20px;
  animation: scrollingX 30s linear infinite;
}
.best-section .best-container .right .down .marquee-container .box-1 {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid black;
}
.best-section .best-container .right .down .marquee-container .box-1 .logo-container {
  height: 100px;
  width: 100px;
}
.best-section .best-container .right .down .marquee-container .box-1 .logo-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@keyframes scrollingX {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-144vw);
  }
}
@keyframes scrollingY {
  0% {
    transform: translateX(-144vw);
  }
  100% {
    transform: translatex(0);
  }
}
.faq-section {
  display: flex;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .faq-section {
    flex-direction: column;
  }
}
.faq-section .left {
  flex: 1;
}
.faq-section .left h2 {
  font-weight: 600;
  text-transform: none;
}
.faq-section .left h4 {
  font-weight: 600;
  margin-bottom: 20px;
}
.faq-section .right {
  flex: 1;
}

.faq-row-wrapper .row-title {
  padding: 20px !important;
}
.faq-row-wrapper .faq-row {
  margin-bottom: 10px;
  border-radius: 10px;
  border: 0 !important;
  background-color: #FFFAFE;
}
.faq-row-wrapper .icon-wrapper {
  height: 30px;
  width: 30px;
  max-height: 40px !important;
  max-width: 40px !important;
  border-radius: 5px;
  background-color: #4B9E99;
  text-align: center;
  display: grid;
  place-items: center;
  color: white !important;
}
.faq-row-wrapper section {
  margin-top: 0;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
}

.icon-container {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.icon-container svg {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.loader-animation {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-container .img-container {
  height: 600px;
  width: 100%;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .banner-container .img-container {
    height: unset;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .banner-container .img-container {
    height: unset;
  }
}
.banner-container .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.img-1 {
  position: absolute;
  z-index: 100;
  height: 200px;
  width: 200px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .img-1 {
    display: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .img-1 {
    display: none;
  }
}
.img-1 img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.contact-us-section .contact-us-container {
  display: flex;
  gap: 50px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .contact-us-section .contact-us-container {
    flex-direction: column;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .contact-us-section .contact-us-container {
    flex-direction: column;
  }
}
.contact-us-section .contact-us-container .left {
  flex: 1;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .contact-us-section .contact-us-container .left .content {
    margin-top: 20px;
  }
}
@media screen and (min-width: 481px) and (max-width: 820px) {
  .contact-us-section .contact-us-container .left .content {
    margin-right: 0px;
  }
}
.contact-us-section .contact-us-container .left .content .icon-text-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
}
.contact-us-section .contact-us-container .left .content .icon-text-container .icon-text .icon-container {
  height: 30px;
  width: 30px;
  margin-bottom: 10px;
}
.contact-us-section .contact-us-container .right {
  flex: 2;
}
.contact-us-section .contact-us-container .right iframe {
  height: 600px;
  width: 100%;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .contact-us-section .contact-us-container .right iframe {
    height: 300px;
  }
}
@media screen and (min-width: 481px) and (max-width: 820px) {
  .contact-us-section .contact-us-container .right iframe {
    height: 530px;
  }
}/*# sourceMappingURL=index.css.map */