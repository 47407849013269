$pink:#FFFAFE;
$green:#4B9E99;
$yellow:#F9D54A;
$darkgrey:#7E7E7E;
$black:#262626;


.table-container{
    overflow-x: auto;
}
table{
    margin-top: 20px;
    border-collapse:collapse ;
    width: 100%;
    thead{
        tr{
            th{
                font-size: 0.7rem!important;
                color: #C8C8C8!important;
                font-weight: 500!important;
                border-bottom: 1px solid #E9E9E9!important;
                padding: 10px 5px!important;
                &:first-child{
                    text-align: left!important;
                }
                &:last-child{
                    text-align: right!important;
                }
            }
        }
        
    }
    tbody{
        tr{
            td{
                padding: 20px ;
                border-bottom: 1px solid #E9E9E9;
                &:first-child{
                    text-align: left;
                    padding: 10px 0;

                }
                &:last-child{
                    text-align: right;
                }
                .flex-container{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    
                    .name{
                        flex:1;
                        padding: 0 10px;
                        
                    }   
                }
                .img-container{
                    height: 100px;
                    width: 100px;
                    background-color: #EFF4FF;
                    border-radius: 10px;
                    padding: 10px;
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
                .icon-container{
                    height: 30px;
                    width: 30px;
                    cursor: pointer;
                    svg{
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
                .button{
                    padding: 5px 10px ;

                }
                .customer{
                    color: #4A90F9;
                    background-color: rgba(74, 144, 249, 0.12);
                    border-radius: 5px;
                    border: 1px solid #4A90F9;
                    font-weight: normal;
                }
                
                .school{
                    color: #C14AF9;
                    background: rgba(193, 74, 249, 0.12);
                    border-radius: 5px;
                    border: 1px solid #C14AF9;
                    font-weight: normal;
                }
                .Enable,.in-stock{
                    color: #34C83A;
                    background: rgba(52, 200, 58, 0.12);
                    border-radius: 5px;
                    border: 1px solid #34C83A;
                    font-weight: normal;
                }
                .Disable,.out-of-stock{
                    color: #B72126;
                    background: rgba(183, 33, 38, 0.13);
                    border-radius: 5px;
                    border: 1px solid #B72126;
                    font-weight: normal;
                }
                .category-list{
                    display: flex;
                    gap: 10px;
                    .category{
                        padding: 5px;
                        border-radius: 10px;
                        border: 1px solid $green;
                        background-color: #4b9e9931;
                        color: $green;
                    }
                }
            }
           
        }
    }
}
.img-table{
    th{
        text-align: left;
    }
    td{
        text-align: left;
    }
}

.pagination{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}