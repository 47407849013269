$primary:    #4B9E99;
$secondary:  #F9D54A;

.product-card {
    border-radius: 10px;
    width: 100%;
    border: 1px solid #D3D3D3;
    position: relative;
    max-width: 300px;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        min-height:  unset;
        max-width:  unset;
    }
    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        max-width: 400px;
    }
    .content {
        padding: 20px;
        .product-img {
            position: relative;
            background-color: white;

            .img-container {
                height: 200px;
                width: 100%;
                @media screen and (min-width: 300px) and (max-width: 480px) {
                    height: 230px;
                }
            
                // iPads, Tablets
                @media screen and (min-width: 481px) and (max-width: 1200px) {
                    height: 230px;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
            .filled{
                svg{
                    path{
                        fill: #F54C4C;
                    }
                }
            }
            .icon-container {
                position: absolute;
                height: 25px;
                width: 25px;
                top: 20px;
                right: 10px;

                svg {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
        .product-description{
            font-size: 14px;
            display: flex;
            gap: 10px;
            align-items: center;
            font-weight: 600;
            color: rgb(212, 154, 52);
            .icon-container{
            }
        }
        .product-name {
            font-weight: 700;
            margin-top: 20px;
            font-size: 1.2rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1; /* Limit to 3 lines */
            line-clamp: 1;
            text-overflow: ellipsis;
            @media screen and (min-width: 300px) and (max-width: 480px) {
                font-size: 0.8rem;
            }
        }
        .stock{
            color: #34C83A;
            margin-top: 20px;
            font-size: 0.8rem;
            display: none;
        }

    }
    .btn-container{
    }
}


