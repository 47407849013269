.table-container {
  overflow-x: auto;
}

table {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
}
table thead tr th {
  font-size: 0.7rem !important;
  color: #C8C8C8 !important;
  font-weight: 500 !important;
  border-bottom: 1px solid #E9E9E9 !important;
  padding: 10px 5px !important;
}
table thead tr th:first-child {
  text-align: left !important;
}
table thead tr th:last-child {
  text-align: right !important;
}
table tbody tr td {
  padding: 20px;
  border-bottom: 1px solid #E9E9E9;
}
table tbody tr td:first-child {
  text-align: left;
  padding: 10px 0;
}
table tbody tr td:last-child {
  text-align: right;
}
table tbody tr td .flex-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
table tbody tr td .flex-container .name {
  flex: 1;
  padding: 0 10px;
}
table tbody tr td .img-container {
  height: 100px;
  width: 100px;
  background-color: #EFF4FF;
  border-radius: 10px;
  padding: 10px;
}
table tbody tr td .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
table tbody tr td .icon-container {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
table tbody tr td .icon-container svg {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
table tbody tr td .button {
  padding: 5px 10px;
}
table tbody tr td .customer {
  color: #4A90F9;
  background-color: rgba(74, 144, 249, 0.12);
  border-radius: 5px;
  border: 1px solid #4A90F9;
  font-weight: normal;
}
table tbody tr td .school {
  color: #C14AF9;
  background: rgba(193, 74, 249, 0.12);
  border-radius: 5px;
  border: 1px solid #C14AF9;
  font-weight: normal;
}
table tbody tr td .Enable, table tbody tr td .in-stock {
  color: #34C83A;
  background: rgba(52, 200, 58, 0.12);
  border-radius: 5px;
  border: 1px solid #34C83A;
  font-weight: normal;
}
table tbody tr td .Disable, table tbody tr td .out-of-stock {
  color: #B72126;
  background: rgba(183, 33, 38, 0.13);
  border-radius: 5px;
  border: 1px solid #B72126;
  font-weight: normal;
}
table tbody tr td .category-list {
  display: flex;
  gap: 10px;
}
table tbody tr td .category-list .category {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #4B9E99;
  background-color: rgba(75, 158, 153, 0.1921568627);
  color: #4B9E99;
}

.img-table th {
  text-align: left;
}
.img-table td {
  text-align: left;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}/*# sourceMappingURL=TableComponents.css.map */