$primary: #4B9E99;
$secondary: #F9D54A;

header {

    background-color: white;
    width: 100%;
    top: 0;
    z-index: 4;
    position: sticky;
    top: 0;
    @media screen and (min-width: 300px) and (max-width: 480px) {
        margin-bottom: 0;
    }
    .desktop-nav {
        display: flex;
        align-items: center;
        padding: 15px 40px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        position: relative;
        .MuiInputBase-root {
            background-color: transparent;
        }

        @media screen and (min-width: 300px) and (max-width: 480px) {
            display: none;
        }

        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            display: none;
        }

            .marquee-text{
                width: 60%;
                position: absolute;
                top: 5px;
                right: 0;
                color: $primary;
                font-weight: 600;
            }

        .left {
            display: flex;
            align-items: center;
        }

        .center {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left:50px ;
            flex: 1;
            form{
                width: 100%;
            }
        }

        .right {
            flex:5;
            display: flex;
            justify-content: flex-end;

            nav {
                display: flex;
                align-items: center;

                ul {
                    display: flex;
                    list-style: none;
                    align-items: center;
                    margin: 0;

                    li {
                        padding-right: 30px;
                        color: $primary;

                        transition: all 0.5s ease;
                        font-weight: 600;

                        a {
                            color: $primary;

                            transition: all 0.5s ease;
                            font-weight: 600;

                            &:hover {
                                color: #2f6d69;
                            }
                        }

                        .icon-container {
                            height: 30px;
                            width: 30px;
                            position: relative;

                            svg {
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                                display: block;
                            }

                            .text-container {
                                height: 25px;
                                width: 25px;
                                background-color: $secondary;
                                color: black;
                                font-size: 0.8rem;
                                display: grid;
                                place-items: center;
                                position: absolute;
                                top: -10px;
                                right: -10px;
                                border-radius: 20px;
                            }
                        }

                        .cart {}
                    }

                    .profile-btn {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        border: 1px solid $primary;
                        color: white;
                        background-color: $primary;
                    }

                    .login {
                        margin-right: 40px;
                    }
                }
            }
        }
    }

    .mobile-nav-bar {
        padding: 10px 10px;
        border-bottom: 1px solid #E8E8E8;
        display: none;

        .MuiInputBase-root {
            background-color: transparent;
        }

        @media screen and (min-width: 300px) and (max-width: 480px) {
            display: block;
        }
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            display: block;
        }
        .mobile-nav {
            align-items: center;
            display: none;

            @media screen and (min-width: 300px) and (max-width: 480px) {
                display: flex;
                flex-direction: row;
            }



            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                display: flex;
                flex-direction: row;
            }

            .left {
                flex: 1;

                .logo-container {
                    width: 150px;
                    float: left;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }

            .right {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .mobile-list {
                    position: fixed;
                    right: 0;
                    top: 0;
                    height: 100%;
                    background-color: white;
                    border-left: 1px solid #E8E8E8;
                    z-index: 300;
                    transition: all 0.5s ease;
                    padding: 40px;
                    padding-top: 100px;

                    nav {
                        margin-top: 20px;

                        ul {
                            list-style: none;
                            padding: 0;
                            margin: 0;

                            li {
                                color: white;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }

                .active {
                    width: 80%;
                    transition: all 0.5s ease;
                    right: 0;
                }

                .inactive {
                    width: 0%;
                    transition: all 0.5s ease;
                    right: -60%;
                }

                .hamburger-react {
                    z-index: 301;
                }
            }
        }
    }

}