$pink:#FFFAFE;
$green:#4B9E99;
$yellow:#F9D54A;
$darkgrey:#7E7E7E;

body {
    background-color: #FBFCFF;

}

.slidebar {
    position: fixed;
    max-width: 300px;
    height: 100%;
    // background-color: red;
    background-color: white;
    border-right: 1px solid #E7E7E7;
    top: 0;
    z-index: 4;

    @media screen and (min-width: 300px) and (max-width: 480px) {
        display: none;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        display: none;
    }

    .content {
        margin: 40px;
        height: 100%;
        position: relative;
        .logo-container {
            height: 80px;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        .navbar {
            margin-top: 40px;

            nav {
                ul {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .active {
                        li {
                            background: rgba(75, 158, 153, 0.15);
                            color: $green;

                            .icon-container {
                                svg {
                                    path {
                                        stroke: $green;
                                    }
                                }
                            }
                        }
                    }

                    .unactive {
                        li {
                            background: none !important;
                            color: #7E7E7E !important;

                            .icon-container {
                                svg {
                                    path {
                                        stroke: #7e7e7e !important;
                                        ;
                                    }
                                }
                            }
                        }
                    }

                    li {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #7E7E7E;
                        font-weight: 500;
                        cursor: pointer;
                        padding: 10px;
                        transition: all 0.5s ease;
                        border-radius: 5px;

                        .icon-container {
                            height: 30px;
                            width: 30px;

                            svg {
                                height: 100%;
                                width: 100%;

                                path {
                                    transition: all 0.5s ease;
                                    stroke: #7E7E7E;
                                }
                            }
                        }

                        &:hover {
                            background: rgba(75, 158, 153, 0.15);
                            color: $green;

                            .icon-container {
                                svg {
                                    path {
                                        stroke: $green;
                                    }
                                }
                            }
                        }
                    }

                    .products-submenu {
                        display: flex;
                        flex-direction: column;
                        margin-left: 50px;
                        gap: 10px;

                        .active {
                            color: $green !important;
                        }

                        a {
                            color: #7E7E7E !important;

                            &:hover {
                                color: $green !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .button{
        position: absolute;
        bottom: 80px;
        width: 100%;
        left: 0;
        right: 0;
        text-align: center;
    }
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 3;
    border-bottom: 1px solid #E7E7E7;

    .content {
        padding: 20px 40px;
        display: flex;
        justify-content: flex-end;

        @media screen and (min-width: 300px) and (max-width: 480px) {
            padding: 20px;
        }

        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            padding: 20px;
        }

        .login-btn {
            display: flex;
            align-items: center;
            gap: 10px;
            color: $darkgrey;
            font-weight: 600;

            @media screen and (min-width: 300px) and (max-width: 480px) {
                display: none;
            }

            @media screen and (min-width: 481px) and (max-width: 1200px) {
                display: none;
            }

            .icon-container {
                height: 30px;
                width: 30px;

                svg {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .mobile-header {
            padding: 10px 10px;
            align-items: center;
            display: none;
            width: 100%;

            @media screen and (min-width: 300px) and (max-width: 480px) {
                display: flex;
            }

            // iPads, Tablets
            @media screen and (min-width: 481px) and (max-width: 1200px) {
                display: flex;
            }

            .left {
                flex: 1;

                .logo-container {
                    width: 150px;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }

            .right {
                flex: 1;
                display: flex;
                justify-content: flex-end;

                .mobile-list {
                    position: fixed;
                    right: 0;
                    top: 0;
                    height: 100%;
                    background-color: white;
                    border-left: 1px solid #E8E8E8;
                    z-index: 300;
                    transition: all 0.5s ease;
                    padding: 40px;
                    padding-top: 100px;
                    height: 100vh;
                    overflow: scroll;
                    .navbar {
                        margin-top: 40px;

                        nav {
                            ul {
                                margin: 0;
                                padding: 0;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;

                                .active {
                                    li {
                                        background: rgba(75, 158, 153, 0.15);
                                        color: $green;

                                        .icon-container {
                                            svg {
                                                path {
                                                    stroke: $green;
                                                }
                                            }
                                        }
                                    }
                                }

                                .unactive {
                                    li {
                                        background: none !important;
                                        color: #7E7E7E !important;

                                        .icon-container {
                                            svg {
                                                path {
                                                    stroke: #7e7e7e !important;
                                                    ;
                                                }
                                            }
                                        }
                                    }
                                }

                                li {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    color: #7E7E7E;
                                    font-weight: 500;
                                    cursor: pointer;
                                    padding: 10px;
                                    transition: all 0.5s ease;
                                    border-radius: 5px;

                                    .icon-container {
                                        height: 30px;
                                        width: 30px;

                                        svg {
                                            height: 100%;
                                            width: 100%;

                                            path {
                                                transition: all 0.5s ease;
                                                stroke: #7E7E7E;
                                            }
                                        }
                                    }

                                    &:hover {
                                        background: rgba(75, 158, 153, 0.15);
                                        color: $green;

                                        .icon-container {
                                            svg {
                                                path {
                                                    stroke: $green;
                                                }
                                            }
                                        }
                                    }
                                }

                                .products-submenu {
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: 50px;
                                    gap: 10px;

                                    .active {
                                        color: $green !important;
                                    }

                                    a {
                                        color: #7E7E7E !important;

                                        &:hover {
                                            color: $green !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .active {
                    transition: all 0.5s ease;
                    right: 0;
                }

                .inactive {
                    transition: all 0.5s ease;
                    right: -80%;
                }

                .hamburger-react {
                    z-index: 301;
                }
            }
        }
    }
}

.dashboard-main {
    padding: 40px;

    @media screen and (min-width: 300px) and (max-width: 480px) {
        padding: 20px;
    }

    @media screen and (min-width: 481px) and (max-width: 1200px) {
        padding: 20px;
    }
}

// .box {
//     height: 300px;
//     width: 400px;
//     background-color: white;
// }

