.category-card {
  padding: 30px 20px;
  border-radius: 5px;
  transition: all 0.5s ease;
  display: grid;
  place-items: center;
  min-height: 280px;
  background-color: rgba(249, 214, 74, 0.1921568627);
  transition: all 0.5s ease;
}
.category-card:hover .category-img {
  transform: scale(1.1);
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .category-card {
    padding: 20px 5px;
    min-height: 180px;
  }
}
.category-card .category-img {
  height: 150px;
  width: 100%;
  margin: auto;
  transition: all 0.5s ease;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .category-card .category-img {
    height: 80px;
    width: 100%;
    margin: auto;
  }
}
.category-card .category-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.category-card .category-name {
  margin-top: 10px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
}/*# sourceMappingURL=toy-category-slider.css.map */