
.client-slider-container {
    margin-top: 10px;
    padding: 20px;
    position: relative;

    background-color: #EFF4FF;
    border-radius: 5px;

    .client-slider {

        .client {
            display: grid !important;
            margin: auto !important;
            max-width: 400px;
            padding: 20px;
            background-color: white;
            border-radius: 5px;

            .client-name {
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
    }
}