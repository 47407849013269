body {
  background-color: #FBFCFF;
}

.slidebar {
  position: fixed;
  max-width: 300px;
  height: 100%;
  background-color: white;
  border-right: 1px solid #E7E7E7;
  top: 0;
  z-index: 4;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .slidebar {
    display: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .slidebar {
    display: none;
  }
}
.slidebar .content {
  margin: 40px;
  height: 100%;
  position: relative;
}
.slidebar .content .logo-container {
  height: 80px;
  width: 100%;
}
.slidebar .content .logo-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.slidebar .content .navbar {
  margin-top: 40px;
}
.slidebar .content .navbar nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.slidebar .content .navbar nav ul .active li {
  background: rgba(75, 158, 153, 0.15);
  color: #4B9E99;
}
.slidebar .content .navbar nav ul .active li .icon-container svg path {
  stroke: #4B9E99;
}
.slidebar .content .navbar nav ul .unactive li {
  background: none !important;
  color: #7E7E7E !important;
}
.slidebar .content .navbar nav ul .unactive li .icon-container svg path {
  stroke: #7e7e7e !important;
}
.slidebar .content .navbar nav ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #7E7E7E;
  font-weight: 500;
  cursor: pointer;
  padding: 10px;
  transition: all 0.5s ease;
  border-radius: 5px;
}
.slidebar .content .navbar nav ul li .icon-container {
  height: 30px;
  width: 30px;
}
.slidebar .content .navbar nav ul li .icon-container svg {
  height: 100%;
  width: 100%;
}
.slidebar .content .navbar nav ul li .icon-container svg path {
  transition: all 0.5s ease;
  stroke: #7E7E7E;
}
.slidebar .content .navbar nav ul li:hover {
  background: rgba(75, 158, 153, 0.15);
  color: #4B9E99;
}
.slidebar .content .navbar nav ul li:hover .icon-container svg path {
  stroke: #4B9E99;
}
.slidebar .content .navbar nav ul .products-submenu {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  gap: 10px;
}
.slidebar .content .navbar nav ul .products-submenu .active {
  color: #4B9E99 !important;
}
.slidebar .content .navbar nav ul .products-submenu a {
  color: #7E7E7E !important;
}
.slidebar .content .navbar nav ul .products-submenu a:hover {
  color: #4B9E99 !important;
}
.slidebar .button {
  position: absolute;
  bottom: 80px;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 3;
  border-bottom: 1px solid #E7E7E7;
}
.header .content {
  padding: 20px 40px;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .header .content {
    padding: 20px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .header .content {
    padding: 20px;
  }
}
.header .content .login-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #7E7E7E;
  font-weight: 600;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .header .content .login-btn {
    display: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .header .content .login-btn {
    display: none;
  }
}
.header .content .login-btn .icon-container {
  height: 30px;
  width: 30px;
}
.header .content .login-btn .icon-container svg {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header .content .mobile-header {
  padding: 10px 10px;
  align-items: center;
  display: none;
  width: 100%;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .header .content .mobile-header {
    display: flex;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .header .content .mobile-header {
    display: flex;
  }
}
.header .content .mobile-header .left {
  flex: 1;
}
.header .content .mobile-header .left .logo-container {
  width: 150px;
}
.header .content .mobile-header .left .logo-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header .content .mobile-header .right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.header .content .mobile-header .right .mobile-list {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background-color: white;
  border-left: 1px solid #E8E8E8;
  z-index: 300;
  transition: all 0.5s ease;
  padding: 40px;
  padding-top: 100px;
  height: 100vh;
  overflow: scroll;
}
.header .content .mobile-header .right .mobile-list .navbar {
  margin-top: 40px;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul .active li {
  background: rgba(75, 158, 153, 0.15);
  color: #4B9E99;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul .active li .icon-container svg path {
  stroke: #4B9E99;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul .unactive li {
  background: none !important;
  color: #7E7E7E !important;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul .unactive li .icon-container svg path {
  stroke: #7e7e7e !important;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #7E7E7E;
  font-weight: 500;
  cursor: pointer;
  padding: 10px;
  transition: all 0.5s ease;
  border-radius: 5px;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul li .icon-container {
  height: 30px;
  width: 30px;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul li .icon-container svg {
  height: 100%;
  width: 100%;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul li .icon-container svg path {
  transition: all 0.5s ease;
  stroke: #7E7E7E;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul li:hover {
  background: rgba(75, 158, 153, 0.15);
  color: #4B9E99;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul li:hover .icon-container svg path {
  stroke: #4B9E99;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul .products-submenu {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  gap: 10px;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul .products-submenu .active {
  color: #4B9E99 !important;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul .products-submenu a {
  color: #7E7E7E !important;
}
.header .content .mobile-header .right .mobile-list .navbar nav ul .products-submenu a:hover {
  color: #4B9E99 !important;
}
.header .content .mobile-header .right .active {
  transition: all 0.5s ease;
  right: 0;
}
.header .content .mobile-header .right .inactive {
  transition: all 0.5s ease;
  right: -80%;
}
.header .content .mobile-header .right .hamburger-react {
  z-index: 301;
}

.dashboard-main {
  padding: 40px;
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .dashboard-main {
    padding: 20px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .dashboard-main {
    padding: 20px;
  }
}/*# sourceMappingURL=layout.css.map */