$pink:#FFFAFE;
$green:#4B9E99;
$yellow:#F9D54A;
$black:#262626;




.case-studies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 10px;
    margin-top: 20px;

    @media screen and (min-width: 300px) and (max-width: 480px) {
        flex-direction: column;
    }

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }

    .case {
        border: 1px solid #B9B9B9;
        border-radius: 5px;
        min-height: 400px;

        @media screen and (min-width: 481px) and (max-width: 1200px) {
            min-height: 200px;
        }

        @media screen and (min-width: 300px) and (max-width: 480px) {
            min-height: 200px;
        }

        .content {
            padding: 30px;

            @media screen and (min-width: 481px) and (max-width: 1200px) {
                padding: 20px;
            }

            @media screen and (min-width: 300px) and (max-width: 480px) {
                padding: 20px;
            }

            .case-img {
                height: 200px;
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .case-title {
                margin-top: 50px;
                font-size: 1.2rem;
                font-weight: 600;
                text-align: justify;

                @media screen and (min-width: 300px) and (max-width: 480px) {
                    margin-top: 20px;
                }
            }

            .date {
                color: $green;
                margin-top: 10px;
                font-size: 0.8rem;
                display: none;
            }

            .short-description {
                margin-top: 20px;
                text-align: justify;
                font-size: 0.8rem;
            }

            .tag-container {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
                gap: 5px;

                .tag {
                    padding: 5px 5px;
                    background-color: #EBEBEB;
                    font-size: 0.8rem;
                    border-radius: 5px;
                    color: #B9B9B9;
                    display: none;
                }
            }

            a {
                .readmore {
                    margin-top: 30px;
                    text-decoration: underline !important;
                    color: $green;
                    font-weight: 700;
                    letter-spacing: 0.32rem;
                    font-size: 1rem;
                }
            }

        }
    }
}

.case-detail-container {
    @media screen and (min-width: 300px) and (max-width: 480px) {}

    // iPads, Tablets
    @media screen and (min-width: 481px) and (max-width: 1200px) {
        padding-top: 0;
    }

    .right {

        @media screen and (min-width: 300px) and (max-width: 480px) {
            display: none;
        }

        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            display: none;
        }
    }

    .left {
        @media screen and (min-width: 300px) and (max-width: 480px) {
            border: 0;
            padding: 0;
        }

        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {
            border: 0;
            padding: 0;

        }

    }
}

.case-study-detail {
    .case-study-banner {
        width: 100%;

       

        // iPads, Tablets
        @media screen and (min-width: 481px) and (max-width: 1200px) {}

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .case-study-title-container {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: 300px) and (max-width: 480px) {
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 20px;
        }

        .case-study-title {
            h1 {
                font-size: 2.5rem;
                text-transform: uppercase;
                font-weight: 900;
                line-height: 40px;
                margin: 0;
                @media screen and (min-width: 300px) and (max-width: 480px) {
                    font-size: 1.8rem;
                }
            }

            .subtitle {

                h2 {
                    font-size: 1.5rem;
                    font-weight: 400;
                    color: $yellow;

                    @media screen and (min-width: 300px) and (max-width: 480px) {
                        font-size: 1.2rem;
                        line-height: 28px;
                    }
                }
            }

            .tag-container {
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;
                gap: 5px;

                .tag {
                    padding: 5px 5px;
                    background-color: #EBEBEB;
                    font-size: 0.8rem;
                    border-radius: 5px;
                    color: #B9B9B9;
                }
            }
        }

        .date {
            color: $green;
            display: none;
        }
    }

    .case-study {
        margin-top: 50px;

        .section {
            margin-bottom: 50px;

            .heading {
                text-transform: uppercase;
                letter-spacing: 0.32em;
                font-weight: 700;
                color: $green;
            }
        }
    }
}